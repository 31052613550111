import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core';
import moment from 'moment';
import React, { Fragment, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { prepareInvoicingSales } from '../../../../../helpers/sales/prepareDataToSearch';
import { InputSearching } from '../../../../../helpers/tables/InputSearching';
import { Pagination } from '../../../../../helpers/tables/Pagination';
import { CRM_INVOICING_RENOVATIONS_NEW, CRM_INVOICING_RENOVATIONS_UPDATE } from '../../../../constants/Routes';

export const CrmInvocingRenovationsIndexScreen = () => {
  
  const {crmSalesRenovations} = useSelector(state => state.crmSale);
  const { isBoss, descriptionRole } = useSelector(state => state.auth.user);
  
  const [salesInvoicing, setSalesInvoicing] = useState([]);
  const [salesSearch, setSalesSearch] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  useEffect(() => {
    if (crmSalesRenovations.length > 0) {
      setSalesInvoicing(prepareInvoicingSales(crmSalesRenovations.reverse()));
      setSalesSearch(prepareInvoicingSales(crmSalesRenovations.reverse()));
    }
  }, [crmSalesRenovations, setSalesInvoicing, setSalesSearch]);

  return (
    <div>
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right mb-3">
          { (isBoss || descriptionRole === 'Seller') &&
              (<Link to={CRM_INVOICING_RENOVATIONS_NEW} className="btn btn-primary">
               <i className="fas fa-plus"></i> Agregar renovación
              </Link>)
          }
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <InputSearching
            columnsToSearch={ [ 'email', 'fullName', 'rfc' ] }
            setDataChange={ setSalesSearch }
            originals={ salesInvoicing }
          />

          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"><strong>Id</strong></TableCell>
                  <TableCell align="center"><strong>Cliente</strong></TableCell>
                  <TableCell align="center"><strong>Productos</strong></TableCell>
                  <TableCell align="center"><strong>Fecha de pago</strong></TableCell>
                  <TableCell align="center"><strong>Fecha inicio</strong></TableCell>
                  <TableCell align="center"><strong>Fecha final</strong></TableCell>
                  <TableCell align="center"><strong>Vendedor</strong></TableCell>
                  <TableCell align="center"><strong>Activo</strong></TableCell>
                  <TableCell align="center"><strong>Acciones</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  salesSearch.length === 0 ?
                  (
                    <TableRow>
                      <TableCell colSpan="9">
                        <div className="alert alert-info text-center" role="alert">
                          No se encontraron renovaciones
                        </div>
                      </TableCell>
                    </TableRow>
                  )
                  :
                  (
                    salesSearch.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => {
                      return(
                        <TableRow key={index}>
                          <TableCell>{sale.id}</TableCell>
                          <TableCell align="center">
                            {sale.user.name} {sale.user.lastName} {sale.user.motherLastName} <br />
                            <strong>{sale.user.email}</strong><br />
                            {sale.user.phoneMobile}
                          </TableCell>
                          <TableCell align="center">
                            <Fragment>
                              {
                                sale.products.map((product, index) => {
                                  return(
                                    <Fragment key={index}>
                                      {product.product.description}
                                      <strong> {product.product.unitPrice}</strong><br/>
                                    </Fragment>
                                  )
                                })
                              }
                              Total: <strong>{sale.total}</strong>
                            </Fragment>
                          </TableCell>
                          <TableCell align="center">
                            { sale.paymentAt ? 
                              (
                                moment(sale.paymentAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            { sale.startServiceAt ? 
                              (
                                moment(sale.startServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            { sale.endServiceAt ? 
                              (
                                moment(sale.endServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {sale.seller.name}
                          </TableCell>

                          <TableCell align="center">
                            {
                              sale.isAuthorized ?
                              (
                                <span className="badge badge-success">Si</span>
                              ):
                              (
                                <span className="badge badge-danger">No</span>
                              )
                            }
                          </TableCell>
                          <TableCell align="center">
                            <Link to={CRM_INVOICING_RENOVATIONS_UPDATE(sale.id)} data-toggle="tooltip" data-placement="top"
                              title="Editar renovación" className="btn btn-primary mr-2">
                              <i className="fas fa-edit"></i>
                            </Link>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            data={salesSearch}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </div>
    </div>
  )
}
