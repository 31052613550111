import swal from "sweetalert";
import { fetchWithToken, fetchWithTokenFormDataExist, fetchWithTokenWithFile } from "../../helpers/fetch";
import { loadingModal, saveModal, SendingEmailModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";
import { data } from "jquery";

export const crmSalesStartLoading = (date) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken('api/Crm/sales/get', date, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(crmSalesLoaded(body.data));
    }
    loadingModal(false)
  }
}

const crmSalesLoaded = (sales) => ({
  type: types.crmSalesStartLoading,
  payload: sales
})

export const startRegisterCRMSale = (sale, history, route) => {
  /* console.log(sale.get("isSubscription"));
  console.log(sale.get("Frecuency"));
  console.log(sale.get("GatewayId")); */
  return async (dispatch) => {
    saveModal(true);
    const resp = await fetchWithTokenFormDataExist('api/Crm/sales/create', sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    saveModal(false);
  }
}

export const sendEmailNotifyAutorized = (idSale) => {
  return async (dispatch) => {
    SendingEmailModal(true)
    const resp = await fetchWithTokenFormDataExist(`api/Crm/sales/notifyActivate/${idSale}`);
    const body = await resp.json();
    if (body.success) {
      // SendingEmailModal(false)
    }
    SendingEmailModal(false)
  }
}

export const activeRenovationCrm = (saleId) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Admin/orders/authorize/${saleId}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(activeRenovationCrmChange(saleId))
    }
    loadingModal(false)
  }
}

const activeRenovationCrmChange = (saleId) => ({
  type: types.crmRenovationActive,
  payload: saleId
})

export const activeSaleCrm = (idSale) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithTokenFormDataExist(`api/Admin/orders/authorize/${idSale}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(activeSaleCrmChange(idSale))
      swal('Exito', 'Se activo el pago de la venta', 'success');
    } else {
      swal('Error ', body.error, 'error');
    }
    loadingModal(false)
  }
}

const activeSaleCrmChange = (idSale) => ({
  type: types.crmSaleActive,
  payload: idSale
})

export const startServiceUpdate = (sale, handleModal) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Crm/sales/updateValidity`, sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(changeServiceDate(sale))
      handleModal();
    }
    loadingModal(false)
  }
}

const changeServiceDate = (sale) => ({
  type: types.serviceDateUpdate,
  payload: sale
})

export const crmSaleOptainSelected = (sale) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Crm/sales/getBySale`, sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(crmSaleObtained(body.data))
    }
    loadingModal(false)
  }
}

export const crmSaleRenovationOptainSelected = (sale) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Admin/orders/getByOrder`, sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(crmSaleObtained(body.data))
    }
    loadingModal(false)
  }
}

const crmSaleObtained = (sale) => ({
  type: types.crmSaleOptainSelected,
  payload: sale
})

export const crmSaleStartUpdate = (sale, history, route) => {
  return async (dispatch) => {
    const resp = await fetchWithTokenFormDataExist('api/Crm/sales/update', sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al actualizar', msgErrors, 'error');
    }
  }
}

export const crmSaleRenovationStartUpdate = (sale, history, route) => {
  return async () => {
    loadingModal(true);
    const resp = await fetchWithTokenFormDataExist('api/Admin/orders/update', sale, 'POST');
    const body = await resp.json();
    if (body.success) {
      history.push(route);
    } else {
      let msgErrors = "";
      if (body.errors) {
        Object.keys(body.errors).forEach(function (key) {
          msgErrors += `${body.errors[key]}\n`
        });
      } else {
        msgErrors = body.error;
      }
      swal('Error al guardar', msgErrors, 'error');
    }
    loadingModal(false)
  }
}

export const obtainSaleVouchersStartLoading = (saleId) => {
  return async (dispatch) => {
    loadingModal(true)
    const resp = await fetchWithToken(`api/Crm/sales/getVouchers/${saleId}`);
    const body = await resp.json();
    if (body.success) {
      dispatch(saleVouchersLoaded({
        saleId: saleId,
        vouchers: body.data
      }));
    }
    loadingModal(false);
  }
}

const saleVouchersLoaded = (vouchers) => ({
  type: types.crmSaleVoucherStartLoading,
  payload: vouchers
})

export const crmSaleSelectedClear = () => ({ type: types.crmSaleClearSelected })

export const getCSVWithRFC = (rfc, isAnual) => {
  return async (dispatch) => {
    loadingModal(true);
    try {
      const resp = await fetchWithToken(`api/Profile/sat-declarations/${rfc}/${isAnual}`);
      const blob = await resp.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', isAnual ? rfc + '_Anual.csv' : rfc + '.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      loadingModal(false);
    } catch (error) {
      console.error('Error downloading CSV:', error);
      loadingModal(false);
    }
  };
};

export const getHelpData = () => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('api/Profile/sat-declarations/help');
      const body = await resp.json();
      if (body.success) {
        dispatch(helpData(body.data))
      }
    } catch (error) {
      console.error(error);
    }
  }
}

const helpData = (help) => ({
  type: types.csvInstructions,
  payload: help
});

export const uploadFileCSV = (file, RFC, isAnnual, IsUpdate) => {
  return async (dispatch) => {
    saveModal(true);
    try {
      const formData = new FormData();
      formData.append('File', file);
      formData.append("RFC", RFC);
      formData.append("isAnnual", isAnnual);
      formData.append("IsUpdate", IsUpdate); // Asegúrate de que 'File' coincida con el nombre del campo en el servidor
      // const data = { formData, RFC, isAnnual, IsUpdate }
      // console.log(data)m
      const resp = await fetchWithTokenWithFile("api/Profile/sat-declarations/", formData, 'POST');
      const body = await resp.json();
      if (body.success) {
        swal('Archivo subido', 'El archivo fue subido con éxito', 'success');
      }
      saveModal(false);
    } catch (error) {
      swal('Archivo no subido', 'Error al subir el archivo', 'error');
      loadingModal(false);
    }
  }
}
