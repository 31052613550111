import swal from "sweetalert";
import { fetchWithApiKey, fetchWithoutToken, fetchWithToken } from "../helpers/fetch";
import { types } from "../types/types";
import { SendingEmailModal } from "../helpers/UseSwal";
import { handleErrorRegister, getErrorType } from "./errorsActions/errorRegister";;


export const startLogin = (user) => {
  return async (dispatch) => {
    const resp = await fetchWithoutToken('api/authenticate', user, 'POST');
    const body = await resp.json();
    if (body.success) {
      localStorage.setItem('token', body.data.token);
      localStorage.setItem('email', user.email);
      localStorage.setItem('password', user.password);
      dispatch(login(body.data))
    } else {
      swal('Error al iniciar sesión', body.error, 'error');
    }
  }
}

const login = (user) => ({
  type: types.authLogin,
  payload: user
});

export const startRegister = (user) => {
  console.log(user)
  return async (dispatch) => {
    // Mostrar el modal de carga
    swal({
      title: 'Cargando...',
      text: 'Por favor, espera mientras se procesa tu registro.',
      icon: 'info',
      button: false, // Oculta el botón
      closeOnClickOutside: false, // Evita que se cierre haciendo clic afuera
      closeOnEsc: false // Evita que se cierre con la tecla Esc
    });

    try {
      const resp = await fetchWithApiKey('api/PreSale/QuickRegister', user, 'POST');
      const body = await resp.json();
      let value;
      if (body) {
        if (body.error) {
          value = body.error;
        } else if (body.errors && body.errors.Rfc && body.errors.Rfc[0]) {
          value = body.errors.Rfc[0];
        } else {
          value = ""
        }
      } else {
        value = ""
      }
      if (body.success) {
        // swal.close();
        swal({
          title: '¡Registro exitoso!',
          text: '¡Gracias por registrarte! Favor de esperar a que nuestro asesor se contacte contigo.',
          icon: 'success',
          button: 'Entendido'
        }).then(() => {
          window.location.href = "/registro";
        });
      } else {
        swal.close();
        const errorType = getErrorType(value);
        const errorControlado = handleErrorRegister(errorType);
        swal(
          'Error en el registro',
          errorControlado.message,
          'error')
          .then(() => {
            if (errorType == 1 || errorType == 4 || errorType == 5) {
              window.location.href = "https://wa.link/6g5z74";
            }
          })
      }
    } catch (error) {
      swal.close();
      swal('Error', 'Hubo un problema al procesar tu solicitud. Por favor, inténtalo de nuevo más tarde.', 'error');
    }
  };
};

export const validatePartner = (code) => {
  return async (dispatch) => {
    const resp = await fetchWithoutToken(`/api/Catalogs/partnerships/${code}/valid`, 'GET');
    const body = await resp.json();
    if (body.success) {
      dispatch(registerValidatePartner(body.success))
      swal({
        title: '¡Código válido!',
        text: `Código de ${body.data.name}.`,
        icon: 'success',
      });
    } else {
      swal({
        title: '¡Código inválido!',
        icon: 'error',
      });
    }
  };
};

const registerValidatePartner = (authPartner) => ({
  type: types.authValidatePartner,
  payload: authPartner
})


export const validatePartnerLink = (code) => {
  return async (dispatch) => {
    const resp = await fetchWithoutToken(`/api/Catalogs/partnerships/${code}/valid`, 'GET');
    const body = await resp.json();
    if (body.success) {
      dispatch(registerValidatePartner(body.success))
      swal({
        title: '¡Código válido!',
        text: `Código de ${body.data.name}.`,
        icon: 'success',
      });
    } else {
      swal({
        title: '¡Código inválido!',
        text: `Código erroneo favor de solicitar el link correcto.`,
        icon: 'error',
        // button: false, // Oculta el botón
        closeOnClickOutside: false, // Evita que se cierre haciendo clic afuera
        closeOnEsc: false
      }).then(() => {
        window.location.href = "/registro";
      });
    }
  };
};



export const startChecking = () => {
  return async (dispatch) => {
    const resp = await fetchWithoutToken('api/authenticate', { email: localStorage.getItem('email'), password: localStorage.getItem('password') }, 'POST');
    const body = await resp.json();
    if (body.success) {
      localStorage.setItem('token', body.data.token);
      dispatch(register(body.data))
    } else {
      dispatch(checkFinish())
    }
  }
}

const register = (user) => ({
  type: types.authRegister,
  payload: user
});


const checkFinish = () => ({ type: types.authCheckingFinish })

export const startLogout = () => {
  return (dispatch) => {
    localStorage.clear();

    dispatch(logout())
  }

}

export const sendMailRecoveryPassword = (email) => {
  return async () => {
    SendingEmailModal(true);
    const resp = await fetchWithoutToken('api/forgotPassword', email, 'POST');
    const body = await resp.json();
    SendingEmailModal(false);
    if (body.success) {
      swal('Correo enviado', 'Se ha enviado con éxito', 'success');
    } else {
      swal('Error al enviar correo', body.error, 'error');
    }
  }
}

export const recoveryPassword = (newPassword) => {
  return async (dispatch) => {
    const resp = await fetchWithToken('api/recoveryPassword', newPassword, 'POST');
    const body = await resp.json();
    if (body.success) {
      swal('Contraseña cambiada', 'Se ha actualizado con éxito', 'success');
    } else {
      swal('Error al actualizar contraseña', body.error, 'error');
    }
  }
}

const logout = () => ({ type: types.authLogout })