import { fetchWithToken } from "../../helpers/fetch";
import { types } from "../../types/types";

export const accountersStartLoading = () => {
  return async(dispatch) => {
    const resp = await fetchWithToken('api/Accounting/accounters');
    const body = await resp.json();
    if (body.success){
        dispatch( accountersLoaded( body.data ) ) 
    }
  }
}

const accountersLoaded = ( accounters ) => ({
  type: types.accounterStartLoading,
  payload: accounters
})