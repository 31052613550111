import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@material-ui/core'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { crmSaleSelectedClear, crmSalesStartLoading, sendEmailNotifyAutorized } from '../../../../actions/crm/sales'
import { obtainMonths, obtainYears } from '../../../../helpers/declarationPeriod'
import { useForm } from '../../../../hooks/useForm'
import { CRM_SALES_FORM, CRM_SALES_PRODUCTS, CRM_SALES_UPDATE_FORM, FORM_PRESALE } from '../../../constants/Routes';
import { CSVLink } from 'react-csv';
import { InputSearching } from '../../../../helpers/tables/InputSearching';
import { Pagination } from '../../../../helpers/tables/Pagination'
import { prepareNewSales } from '../../../../helpers/sales/prepareDataToSearch'

export const CrmSalesIndexScreen = () => {

  const headers = [
    {label: 'Cliente', key: 'userFullName'},
    {label: 'RFC', key: 'userRfc'},
    {label: 'Producto', key: 'products[0].description'},
    {label: 'Vendedor', key: 'sellerFullName'},
    {label: 'Fecha de pago', key: 'paymentAt'},
    {label: 'Venta Autorizada', key: 'active'},   
    {label: 'Cuenta Activa', key: 'activeApp'},
    {label: 'Total', key: 'products[0].total'},
  ]

  const { isBoss, descriptionRole } = useSelector(state => state.auth.user);

  const {crmSales} = useSelector(state => state.crmSale);

  const dispatch = useDispatch();

  const { formValues, setFormValues, handleInputChange } = useForm({
    dataDownload: [],
    monthsOb: obtainMonths(),
    monthSelected: '',
    years: obtainYears().reverse(),
    yearselected: '',
    filtroSales:0
  });

  const { dataDownload, monthSelected, monthsOb, years, yearselected,filtroSales } = formValues;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [sales, setSales] = useState([])
  const [salesSearch, setSalesSearch] = useState([])

  useEffect(() => {
    if(monthSelected !== '' && yearselected !== ''){
      dispatch(crmSaleSelectedClear())
      dispatch(crmSalesStartLoading({
        month: monthSelected,
        year: yearselected
      }))
    }
  }, [dispatch, monthSelected, yearselected]);

  useEffect(() => {
    if (crmSales.length > 0) {
      setSales(prepareNewSales(crmSales));
      setSalesSearch(prepareNewSales(crmSales))
    }
  }, [crmSales, setSales])

  useEffect(() => {
    switch (filtroSales) {
      case '1':
          return(
            setSalesSearch(sales.filter(e => ( e.active == 'si' )))
            )
      case '2':
          return(
            setSalesSearch(sales.filter( e => ( e.active == 'no')))
            )
      case '3':
          return(
            setSalesSearch(sales.filter( e => ( e.activeApp == 'si' )))
          )
      case '4':
        return(
          setSalesSearch(sales.filter( e => ( e.activeApp == 'no' )))
        )
      default:
        setSalesSearch(sales)
  }
  }, [filtroSales])


  useEffect(() => {
    if(crmSales.length > 0){
      let data = [];
      crmSales.forEach(sale => {
        if (sale.isSold) {
          const passing = {
            PaymentAt: moment(sale.paymentAt).format('YYYY-MM-DD'),
            customer: `${sale.user.name} ${sale.user.lastName} ${sale.user.motherLastName}`,
            email: sale.user.email,
            phoneMobile: sale.user.phoneMobile,
            taxpayer: '',
            rfc:  '',
            total: sale.total,
            createdAt: sale.createdAt,
            activeAt: sale.isAuthorized ? sale.updatedAt : ''
          }

          if (sale.user.profiles.length > 0) {
            passing['taxpayer'] = sale.user.profiles[0].businessName;
            passing['rfc'] = sale.user.profiles[0].rfc;
          }

          data.push(passing)
        }
      });

      setFormValues(prevState => {
        return{
          ...prevState,
          dataDownload: data
        }
      })
    }
  }, [crmSales, setFormValues])

  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return{
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues]);

  const sendNotificationAutorized = (idSale) => {
    dispatch(sendEmailNotifyAutorized(idSale))
  }

  return (
    <div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-header">
              <h5>VENTAS</h5>
          </div>
        </div>

        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-2">
              <TextField select variant="outlined" label="Mes" size="small" 
                fullWidth={true} name="monthSelected"
                value={ monthSelected } onChange={handleInputChange}
                SelectProps={{native: true,}}>
                {
                  monthsOb.map((month, index) => {
                    return(
                      <option key={ index } value={ month.month + 1 }>
                        { month.label }
                      </option>
                    )
                  })
                }
              </TextField>
            </div>

            <div className="col-md-2">
              <TextField select variant="outlined" label="Año" size="small" 
                fullWidth={true} name="yearselected"
                value={ yearselected } onChange={handleInputChange}
                SelectProps={{native: true,}}>
                {
                  years.map((yearItem, index) => {
                    return(
                      <option key={ index } value={ yearItem }>
                        { yearItem }
                      </option>
                    )
                  })
                }
              </TextField>
            </div>

            <div className="col-md-2">
              <CSVLink data={salesSearch} filename={"ventas.csv"} headers={headers} className="btn btn-success">
                Descargar CSV
              </CSVLink>
            </div>

            <div className="col-md-6 text-right"> 
              {/* { (isBoss || descriptionRole === 'Seller') && (
                <div>
                  <Link to={CRM_SALES_FORM} className="btn btn-primary float-right">
                    <i className="fas fa-plus"></i> Agregar venta
                  </Link>
                  <Link to={CRM_SALES_PRODUCTS} className="btn btn-info float-right mr-3">
                    <i className="fas fa-plus"></i> Agregar un producto
                  </Link>
                </div>
              )} */}
            </div>
          </div>
        </div>
        <div className="col-md-12 mt-2">
          <div className='row'>
              <div className='col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4'>
                    <TextField
                              select
                              variant="outlined"
                              label="Ventas"
                              size="small"
                              fullWidth={true}
                              name="filtroSales"
                              value={ filtroSales }
                              onChange={handleInputChange}
                              SelectProps={{
                                native: true,
                              }}
                            >
                              <option value={0} >Todas</option>
                              <option value={1} >Autorizadas</option>
                              <option value={2} >No autorizadas</option>
                              <option value={3} >Cuenta Activa</option>
                              <option value={4} >Cuenta No Activa</option>
                    </TextField>
                </div>
            <div className='col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 float-right'>
                <InputSearching
                  columnsToSearch={ [ 'userEmail', 'userFullName', 'userRfc' ] }
                  setDataChange={ setSalesSearch }
                  originals={ sales }
                />
            </div>
          </div>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell align="center"><strong>Id</strong></TableCell>
                  <TableCell align="center"><strong>Cliente</strong></TableCell>
                  <TableCell align="center"><strong>Contribuyente</strong></TableCell>
                  <TableCell align="center"><strong>Producto</strong></TableCell>
                  <TableCell align="center"><strong>Fecha de pago</strong></TableCell>
                  <TableCell align="center"><strong>Fecha inicio</strong></TableCell>
                  <TableCell align="center"><strong>Fecha final</strong></TableCell>
                  <TableCell align="center"><strong>Contador</strong></TableCell>
                  <TableCell align="center"><strong>Vendedor</strong></TableCell>
                  <TableCell align="center"><strong>Venta Autorizada</strong></TableCell>
                  <TableCell align="center"><strong>Cuenta Activa</strong></TableCell>
                  <TableCell align="center"><strong>Suscripción</strong></TableCell>
                  <TableCell align="center"><strong>Acciones</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  salesSearch.length === 0 ?
                  (<TableRow>
                    <TableCell colSpan="10">
                      <div className="alert alert-info text-center" role="alert">
                        No hay ventas con dichos filtros
                      </div>
                    </TableCell>
                  </TableRow>)
                  :
                  (
                    salesSearch.reverse().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sale, index) => {
                      return(
                        <TableRow key={index}>
                          <TableCell>{sale.saleId}</TableCell>
                          <TableCell align="center">
                            {sale.userFullName}<br />
                            <strong>{sale.userEmail}</strong><br />
                            <strong>{sale.referenceCode}</strong><br />
                          </TableCell>
                          <TableCell align="center">
                            {sale.userBussinessName} <br />
                            <strong>{sale.userRfc}</strong>
                          </TableCell>
                          <TableCell align="center">
                            <Fragment>
                              {
                                sale.products.map((product, index) => {
                                  return(
                                    <Fragment key={index}>
                                      {product.description}
                                      <strong> {product.unitPrice}</strong><br/>
                                    </Fragment>
                                  )
                                })
                              }
                              {/* Total: <strong>{sale.total}</strong> */}
                            </Fragment>
                          </TableCell>

                          <TableCell align="center">
                            { sale.paymentAt ? 
                              (
                                moment(sale.paymentAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            { sale.startServiceAt ? 
                              (
                                moment(sale.startServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            { sale.endServiceAt ? 
                              (
                                moment(sale.endServiceAt).format('YYYY-MM-DD')
                              )
                              :
                              (
                                'Sin fecha'
                              )
                            }
                          </TableCell>

                          <TableCell align="center">
                            {sale.accountantFullName}
                          </TableCell>

                          <TableCell align="center">
                            {sale.sellerFullName}
                          </TableCell>

                          <TableCell align="center">
                            {
                              sale.active === 'si' ?
                              (
                                <span className="badge badge-success">Si</span>
                              ):
                              (
                                <span className="badge badge-danger">No</span>
                              )
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              sale.activeApp === 'si' ?
                              (
                                <span className="badge badge-success">Si</span>
                              ):
                              (
                                <span className="badge badge-danger">No</span>
                              )
                            }
                          </TableCell>
                          <TableCell align="center">
                            {
                              sale.paymentLink ?
                               ( <strong>{sale.paymentLink}</strong> )
                               :
                               ( <span className="badge badge-danger">No es suscripción</span> )
                            }
                          </TableCell>
                          <TableCell align="center">
                            <Link to={CRM_SALES_UPDATE_FORM(sale.saleId)} data-toggle="tooltip" data-placement="top"
                              title="Editar venta" className="btn btn-primary mr-2">
                              <i className="fas fa-edit"></i>
                            </Link>

                            <button data-toggle="tooltip" data-placement="top"
                              title="Reenviar Activación" className="btn btn-warning mr-2 mt-2"
                              onClick={sendNotificationAutorized.bind(this, sale.saleId)}
                            ><i className="fas fa-envelope"></i></button>
                          </TableCell>
                        </TableRow>
                      )
                    })
                  )
                }
              </TableBody>
            </Table>
          </TableContainer>
          <Pagination
            data={salesSearch}
            page={page}
            rowsPerPage={rowsPerPage}
            setPage={setPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
    </div>
  )
}
