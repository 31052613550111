import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useDispatch } from 'react-redux';
import { cfdiRelatedsTypeAddToList } from '../../../../../actions/invoices/cfdiRelationTypes';

export const RelatedDocumentsDetailsForm = () => {

  const dispatch = useDispatch();

  const top100Films = [
    { uuid: 'The Shawshank Redemption' },
    { uuid: 'The Godfather' },
    { uuid: 'The Godfather: Part II' },
    { uuid: 'The Dark Knight' },
    { uuid: '12 Angry Men' },
    { uuid: "Schindler's List" },
    { uuid: 'Pulp Fiction' },
    { uuid: 'The Lord of the Rings: The Return of the King' },
    { uuid: 'The Good, the Bad and the Ugly' },
    { uuid: 'Fight Club' },
    { uuid: 'The Lord of the Rings: The Fellowship of the Ring' },
    { uuid: 'Star Wars: Episode V - The Empire Strikes Back' },
    { uuid: 'Forrest Gump' },
    { uuid: 'Inception' },
    { uuid: 'The Lord of the Rings: The Two Towers' },
    { uuid: "One Flew Over the Cuckoo's Nest" },
    { uuid: 'Goodfellas' },
  ];

  const [uuid, setUuid] = useState({
    uuid: ''
  });

  const handleChangeUuid = ( { target } ) =>{
    let value = '';

    if(target.dataset.optionIndex !== undefined){
      value = top100Films[target.dataset.optionIndex].uuid;
    }else{
      value = target.value;
    }

    setUuid({
      uuid: value,
      id: new Date().getTime()
    });
  }

  const cfdiRelatedAdd = () => {
    if( uuid.uuid === '' ){
      console.log('El UUID no puede estar en blanco' );
    }else{
      dispatch( cfdiRelatedsTypeAddToList(uuid) )
      setUuid({
        uuid: ''
      });
    }
  }

  return (
    <div className="row">
      <div className="col-md-6 mb-3">
        <Autocomplete
          freeSolo
          id="free-solo-2-demo"
          disableClearable
          options={top100Films.map((option) => option.uuid)}
          inputValue={ uuid.uuid }
          onChange={handleChangeUuid}
          renderInput={(params) => (
            <TextField
              {...params}
              label="UUID"
              margin="normal"
              variant="outlined"
              size="small"
              onChange={handleChangeUuid}
              InputProps={{ ...params.InputProps, type: 'search' }}
            />
          )}
        />
      </div>

      <div className="col-md-6 mb-3">
        <button
          className="btn btn-primary"
          onClick={ cfdiRelatedAdd }
        >
          Agregar CFDI 
        </button>
      </div>
    </div>
  )
}
