import React, { Fragment, useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getByEmail, validateEmailFlagUser } from '../../../../../actions/users';
import swal from 'sweetalert';

export const ModuleValidateEmail = ({ saleFormValues,saleSetFormValues }) => {

  const dispatch = useDispatch();
  const {getMailUsers, validateEmailUser} = useSelector(state => state.userType);

  useEffect(() => {
    if( getMailUsers.length > 0){
      swal("Exito","Ya existe un Pre-registro del email "+getMailUsers[0].email,"success")
      saleSetFormValues(prevState => {
        return{
          ...prevState,
          customerData:{
            ...prevState.customerData,
            name: getMailUsers[0].name,
            lastName: getMailUsers[0].lastName,
            motherLastName: getMailUsers[0].motherLastName,
            email: getMailUsers[0].email,
            emailNotification: getMailUsers[0].emailNotification,
            phoneMobile: getMailUsers[0].phoneMobile,
          }
        }
       })
     }
     else{
         resetValues();
     }
  }, [dispatch, getMailUsers ])

  useEffect(() => {
    console.log(validateEmailUser)
  }, [dispatch, validateEmailUser ])
  
  
  

  const resetValues = () => {
    dispatch(validateEmailFlagUser(false))
    saleSetFormValues(prevState => {
      return{
        ...prevState,
        customerData:{
          ...prevState.customerData,
          emailNotification: '',
          email: '',
          lastName: '',
          motherLastName: '',
          name: '',
          phoneMobile: '',
        }
      }
    })
  }

  const checkEmail = () => {
    if(saleFormValues.customerData.email){
      dispatch(getByEmail(saleFormValues.customerData.email));
    }else{
      resetValues();
    }
    
  }
  

  return (
    <Fragment >
          <div className=" text-sm-right text-md-left">
            { !validateEmailUser ? 
                (
                <button type="button" className="btn btn-outline-primary" onClick={checkEmail}>
                 <i className="fas fa-search"></i>
              </button>
              ):(
                <button type="button" className="btn btn-outline-primary" onClick={ resetValues }>
                 <i className="fas fa-edit"></i>
              </button>
              )
            }
          </div>
    </Fragment>
  )
}
