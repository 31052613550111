import { types } from "../types/types";

const initialState = {
  users: [],
  sellers: [],
  getMailUsers: [],
  userSelected: {},
  validateEmailUser: false
};


export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.userGetByMail:
      return {
        ...state,
        getMailUsers: [...action.payload]
      }
    case types.userStartLoading:
      return {
        ...state,
        users: [...action.payload]
      }
    case types.userType:
      return {
        ...state,
        sellers: [...action.payload],
      }
    case types.userAddNew:
      return {
        ...state,
        users: [
          ...state.users,
          action.payload
        ]
      }
    case types.userSelectedLoaded:
      return {
        ...state,
        taxpayerSelected: {
          ...action.payload
        }
      }
    case types.userUpdated:
      return {
        ...state,
        users: state.users.map(
          e => (e.id === action.payload.id) ? action.payload : e
        ),
        userSelected: {
          ...action.payload
        }
      }
    case types.userDeleted:
      return {
        ...state,
        users: state.users.filter(
          e => (e.id !== action.payload.id)
        )
      }
    case types.userValidateEmail:
      console.log(action.payload)
      return {
        ...state,
        validateEmailUser: action.payload
      }
    default:
      return state;
  }
}

