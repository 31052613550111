import { TextField } from '@material-ui/core';
import React, { useEffect, useContext, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import swal from 'sweetalert';
import { rolesStartLoading } from '../../../../actions/catalogs/rolesTypes';
import { startUpdateUser, startRegisterUser } from '../../../../actions/users';
import { validateStringPresence } from '../../../../helpers/validateForm';
import { useForm } from '../../../../hooks/useForm';
import { ModalContext } from '../../../modal/ModalContext';



export const UserForm = ({ userGuest = null }) => {

  const { handleModal } = useContext(ModalContext);
  const [msgErrors, setMsgErrors] = useState({});

  const dispatch = useDispatch();

  const { user } = useSelector(state => state.auth);

  const { isBoss } = user;

  const { formValues, setFormValues, handleInputChange } = useForm({

    idRole: '',
    name: '',
    lastName: '',
    motherLastName: '',
    email: '',
    emailNotification: '',
    isActive: 1,
    phoneMobile: '1232334343',
    socialMediaId: 1,
    password: '',
    comments: '',

  });
  const { rolesType } = useSelector(state => state.rolesType)

  const { idRole, name, lastName, motherLastName, email, emailNotification, password, comments } = formValues;

  useEffect(() => {
    if (rolesType.length <= 0) {
      dispatch(rolesStartLoading());
    }
  }, [rolesType, dispatch])

  useEffect(() => {
    if (rolesType.length > 0 && !userGuest) {
      setFormValues(prevState => {
        return {
          ...prevState,
          idRole: rolesType[0].id
        }
      })
    }
  }, [setFormValues, rolesType])


  useEffect(() => {
    if (userGuest) {
      setFormValues(
        userGuest
      )
    }
  }, [userGuest, setFormValues])

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isFormValid()) {
      if (userGuest) {
        dispatch(startUpdateUser({
          ...formValues,
          isActive: userGuest.isActive === 'SI' ? 1 : 0,
          idUser: userGuest.id,
          password: null

        }, handleModal.bind()))
      } else {
        dispatch(startRegisterUser({
          ...formValues,
        }, handleModal.bind()));
      };
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    let stringToValidate = {
      email: email,
      emailNotification: emailNotification,
      lastName: lastName,
      name: name,
    }

    const validateString = validateStringPresence(stringToValidate, setMsgErrors);

    if (validateString) {
      return true;
    } else {
      swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
      return false;
    }
  }

  const handleChange = (e) => {
    e.preventDefault();
  };

  return (
    <div>
      <div className="modal-sm">
        <div className="text-center">
          {
            userGuest ?
              (<h4>Editar Usuario</h4>)
              :
              (<h4>Agregar Nuevo Usuario</h4>)
          }
        </div>
        <hr />

        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              <TextField
                label="Nombre" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="name"
                value={name}
                onChange={handleInputChange}
                helperText={msgErrors.name}
                error={msgErrors.name !== undefined}
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
              <TextField
                label="Apellido paterno" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="lastName"
                value={lastName}
                onChange={handleInputChange}
                helperText={msgErrors.lastName}
                error={msgErrors.lastName !== undefined}
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
              <TextField
                label="Apellido materno" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                name="motherLastName"
                value={motherLastName}
                onChange={handleInputChange}
              />
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {
                isBoss ?
                  (<TextField
                    label="Correo"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth={true}
                    onCut={handleChange}
                    onCopy={handleChange}
                    onPaste={handleChange}
                    helperText={msgErrors.email}
                    error={msgErrors.email !== undefined}
                  />)
                  :
                  (<TextField
                    label="Correo"
                    variant="outlined"
                    size="small"
                    name="email"
                    value={email}
                    onChange={handleInputChange}
                    autoComplete="off"
                    disabled={!!userGuest}
                    fullWidth={true}
                    helperText={msgErrors.email}
                    error={msgErrors.email !== undefined}
                  />
                  )

              }
            </div>

            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {
                isBoss ?
                  (
                    <TextField
                      label="Correo Notificación"
                      variant="outlined"
                      size="small"
                      name="emailNotification"
                      value={emailNotification}
                      onChange={handleInputChange}
                      autoComplete="off"
                      fullWidth={true}
                      onCut={handleChange}
                      onCopy={handleChange}
                      onPaste={handleChange}
                      helperText={msgErrors.emailNotification}
                      error={msgErrors.emailNotification !== undefined}
                    />
                  )
                  :
                  (<TextField
                    label="Correo Notificación"
                    variant="outlined"
                    size="small"
                    name="emailNotification"
                    value={emailNotification}
                    onChange={handleInputChange}
                    autoComplete="off"
                    disabled={!!userGuest}
                    fullWidth={true}
                    helperText={msgErrors.emailNotification}
                    error={msgErrors.emailNotification !== undefined}
                  />
                  )
              }
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {
                isBoss ?
                  (
                    <TextField
                      label="Contraseñasss"
                      variant="outlined"
                      size="small"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                      autoComplete="off"
                      // disabled={!!userGuest}
                      fullWidth={true}
                      helperText={msgErrors.password}
                      error={msgErrors.password !== undefined}
                    />
                  )
                  :
                  (
                    <TextField
                      label="Contraseñasss"
                      variant="outlined"
                      size="small"
                      name="password"
                      value={password}
                      onChange={handleInputChange}
                      autoComplete="off"
                      disabled={!!userGuest}
                      fullWidth={true}
                      helperText={msgErrors.password}
                      error={msgErrors.password !== undefined}
                    />
                  )
              }
            </div>
            <div className="col-md-12 mb-3">
              {
                userGuest ?
                  (<TextField
                    label="Rol"
                    variant="outlined"
                    size="small"
                    name="idRole"
                    value={userGuest.role.id}
                    onChange={handleInputChange}
                    autoComplete="off"
                    disabled={!!userGuest}
                    fullWidth={true}
                    hidden
                    helperText={msgErrors.idRole}
                    error={msgErrors.idRole !== undefined}
                  />)
                  :
                  (<TextField
                    select
                    variant="outlined"
                    label="Selecciona un Rol"
                    size="small"
                    fullWidth={true}
                    name="idRole"
                    value={idRole}
                    onChange={handleInputChange}
                    SelectProps={{
                      native: true,
                    }}
                    helperText={msgErrors.idRole}
                    error={msgErrors.idRole !== undefined}
                  >
                    {
                      rolesType.map((rtActive) => {
                        return (
                          <option key={rtActive.id} value={rtActive.id}>{rtActive.description}</option>
                        )
                      })
                    }
                  </TextField>)
              }

            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
              {
                userGuest ?
                  (<TextField
                    required
                    label="Comentarios"
                    variant="outlined"
                    size="small"
                    name="comments"
                    value={comments}
                    onChange={handleInputChange}
                    autoComplete="off"
                    fullWidth={true}
                  />)
                  :
                  (<TextField
                    hidden
                  />)
              }
            </div>
          </div>
          <div className="col-md-12 text-right pr-0">
            <button type="submit" className="btn btn-primary ml-2">Guardar</button>
          </div>
        </form>
      </div>
    </div>
  )
}