import { InputAdornment, TextField } from '@material-ui/core';
import React, { Fragment, useEffect, useContext } from 'react'
import { useSelector } from 'react-redux';
import { obtainIsrHonorariumPorcentage } from '../../../../../../../../../helpers/accounting/obtainIsrHonorariumPorcentage';
import { obtainMonth } from '../../../../../../../../../helpers/declarationPeriod';
import NumberFormat from 'react-number-format';
import { Depresiations } from '../../expenses/options/depresiations';
import { ModalContext } from '../../../../../../../../modal/ModalContext';
import DepresiationsTable from '../../expenses/options/depresiationsTable';

export const HonirariumBussinessIsrCalculation = ({ formValuesDetailsSummary, setFormValuesDetailsSummary, status }) => {

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { authorizationPrev } = useSelector(state => state.authorizationReport);
  const { IsrHonorariumRanges } = useSelector(state => state.isrHonorarium);

  const { taxpayerProfession } = useSelector(state => state.taxpayer);
  const { handleModal } = useContext(ModalContext);
  const { isrJson } = authorizations;

  const { totalExpenseAutorized, subtotalExpenseAutorized, subtotalIncomesAutorized, taxISRHonorarium, totalISRIncomeAutorized, month, year, total, depresiations } = formValuesDetailsSummary;

  const { expensesAccumulateds, expensesAccumulatedsTotal, expensesSubtotalAutorized, incomesAccumulateds, incomesAccumulatedsTotal, incomesSubtotalAutorized,
    depresiationFixedAsset, depresiationAcumulated, nameMonth,
    taxableBaseTotal, ISR, retentionPrevMonths, retentionPaidPrev } = taxISRHonorarium;

  const isrTotal = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const subtotalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(isrTotal)

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRHonorarium: {
          ...prevState.taxISRHonorarium,
          nameMonth: obtainMonth(month - 1)
        }
      }
    })
  }, [month, setFormValuesDetailsSummary]);


  useEffect(() => {
    if (Object.keys(isrJson).length != 0) {

      let incomesAutorized = subtotalIncomesAutorized;
      let incomesAccumulatedTotal = (parseFloat(incomesAccumulateds) + parseFloat(incomesAutorized)).toFixed(2);
      let expenseAutorized = 0.00;
      if (taxpayerProfession === 'Médico/Dentista') {
        expenseAutorized = parseFloat(totalExpenseAutorized) + parseFloat(authorizations?.paymentComplementExpensesAmount);
        //  expenseAutorized =  total;
      } else {
        let subtotalExpenseAutorizedForm = parseFloat(subtotalExpenseAutorized)
        let paymentComplementExpensesSubtotal = parseFloat(authorizationPrev.paymentComplementExpensesSubtotal)
        // console.log('paymentComplementExpensesSubtotal', paymentComplementExpensesSubtotal)

        subtotalExpenseAutorizedForm = isNaN(subtotalExpenseAutorizedForm) ? 0 : subtotalExpenseAutorizedForm
        paymentComplementExpensesSubtotal = isNaN(paymentComplementExpensesSubtotal) ? 0 : paymentComplementExpensesSubtotal

        // expenseAutorized = subtotalExpenseAutorizedForm + paymentComplementExpensesSubtotal
        expenseAutorized = subtotalISR
        // console.log('expenseAutorized',expenseAutorized)
      }
      let expensesAccumulatedsForm = parseFloat(expensesAccumulateds)
      expensesAccumulatedsForm = isNaN(expensesAccumulateds) ? 0 : expensesAccumulateds

      // let expensesAcu = parseFloat(subtotalISR) + parseFloat(expensesAccumulatedsForm)
      let expensesAcu = parseFloat(expensesSubtotalAutorized) + parseFloat(expensesAccumulatedsForm) 
      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRHonorarium: {
            ...prevState.taxISRHonorarium,
            expensesAccumulatedsTotal: expensesAcu,
            expensesSubtotalAutorized: expenseAutorized,
            incomesSubtotalAutorized: incomesAutorized,
            incomesAccumulatedsTotal: incomesAccumulatedTotal
          }
        }
      })
    }
  }, [incomesAccumulateds, isrJson, setFormValuesDetailsSummary, subtotalExpenseAutorized, subtotalIncomesAutorized, taxpayerProfession, expensesSubtotalAutorized]);

  useEffect(() => {
    const taxableBase = (parseFloat(incomesAccumulatedsTotal) - parseFloat(expensesAccumulatedsTotal) - parseFloat(depresiationAcumulated)).toFixed(2);
    let taxableBaseTotal = taxableBase
    if (parseFloat(taxableBase) <= 0) {
      taxableBaseTotal = 0.00
    }
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRHonorarium: {
          ...prevState.taxISRHonorarium,
          taxableBase: taxableBase,
          taxableBaseTotal: taxableBaseTotal
        }
      }
    })
  }, [depresiationAcumulated, expensesAccumulatedsTotal, incomesAccumulatedsTotal, setFormValuesDetailsSummary])

  useEffect(() => {
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRHonorarium: {
          ...prevState.taxISRHonorarium,
          ISR: obtainIsrHonorariumPorcentage(IsrHonorariumRanges, taxableBaseTotal, month, year, retentionPrevMonths, retentionPaidPrev, totalISRIncomeAutorized)
        }
      }
    })
  }, [IsrHonorariumRanges, retentionPrevMonths, retentionPaidPrev, month, setFormValuesDetailsSummary, taxableBaseTotal, totalISRIncomeAutorized, year, depresiations]);

  // useEffect(() => {
  //   if (depresiations.length > 0) {
  //     setFormValuesDetailsSummary(prevState => {
  //       let ten = [
  //         ...prevState.taxISRHonorarium.depresiations,
  //         ...depresiations
  //       ]
  //       // console.log('TEEEEEN', ten)
  //       ten = { taxISRHonorarium: {
  //         ...prevState.taxISRHonorarium,
  //         depresiations: ten
  //       }}
  //       ten = {
  //         ...prevState,
  //         ...ten,
  //       }
  //       return ten
  //     })
  //   }
  // }, [depresiations]);

  useEffect(() => {
    if (depresiations.length > 0) {
      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRHonorarium: {
            ...prevState.taxISRHonorarium,
            depresiations: [
              ...prevState.taxISRHonorarium.depresiations,
              ...depresiations
            ]
          }
        }
      })
    }
  }, [depresiations]);

  useEffect(() => {
    let depresiationFixedAssetTotal = 0.00;
    let depresiationAcumulatedTotal = 0.00;
    let expensesAccumulateds = 0.00;
    let incomesAccumulateds = 0.00;
    let newDepresiations = [];
    let retentionPaidPrev = 0.00;
    let retentionPrevMonths = 0.00;
    if (Object.keys(authorizationPrev).length > 0) {
      const isr = JSON.parse(authorizationPrev.isrJson);
      // console.log('### isr ### ', isr)
      if (Object.keys(isr).length > 0) {
        if (parseInt(month) !== 1) {
          incomesAccumulateds = parseFloat(isr.incomesAccumulatedsTotal);
          expensesAccumulateds = parseFloat(isr.expensesAccumulatedsTotal);
          retentionPrevMonths = (parseFloat(isr.retentionPrevMonths) + isr.ISR.isrRetentions);
          retentionPaidPrev = (parseFloat(isr.retentionPaidPrev) + parseFloat(isr.ISR.retentionToPay))
        }
        isr.depresiations.forEach(depresiation => {
          const updateAt = new Date(depresiation.updateAt)
          const date = new Date(year, month, 0);
          let newDepresiation = depresiation;
          if (depresiation.monthReal >= 0 && depresiation.monthReal <= depresiation.months) {
            if (updateAt !== date && date > updateAt) {
              newDepresiation['monthReal'] = parseInt(depresiation.monthReal) + 1;
              newDepresiation['updateAt'] = date;
              depresiationFixedAssetTotal = (parseFloat(depresiationFixedAssetTotal) + parseFloat(depresiation.depresiationForMonth)).toFixed(6);
              if (month == 1) {
                newDepresiation['depresiationReal'] = parseFloat(depresiation.depresiationForMonth).toFixed(6);
                depresiationAcumulatedTotal = depresiationFixedAssetTotal;
              }
              else {
                newDepresiation['depresiationReal'] = (parseFloat(depresiation.depresiationReal) + parseFloat(depresiation.depresiationForMonth)).toFixed(6);
                depresiationAcumulatedTotal = (parseFloat(depresiationAcumulatedTotal) + parseFloat(newDepresiation['depresiationReal'])).toFixed(6);
              }
            }
          }
          newDepresiations.push(newDepresiation)
        });
      }
      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRHonorarium: {
            ...prevState.taxISRHonorarium,
            depresiations: newDepresiations,
            depresiationAcumulated: depresiationAcumulatedTotal,
            depresiationFixedAsset: depresiationFixedAssetTotal,
            expensesAccumulateds: expensesAccumulateds,
            incomesAccumulateds: incomesAccumulateds,
            retentionPrevMonths: retentionPrevMonths,
            retentionPaidPrev: retentionPaidPrev,
            totalISRIncomeAutorized: totalISRIncomeAutorized,
          }
        }
      })
    }

    // setFormValuesDetailsSummary(prevState => {
    //   return{
    //     ...prevState,
    //     taxISRHonorarium: {
    //       ...prevState.taxISRHonorarium,
    //       depresiations: newDepresiations,
    //       depresiationAcumulated: depresiationAcumulatedTotal,
    //       depresiationFixedAsset: depresiationFixedAssetTotal,
    //       expensesAccumulateds: expensesAccumulateds,
    //       incomesAccumulateds: incomesAccumulateds,
    //       retentionPrevMonths: retentionPrevMonths,
    //       retentionPaidPrev: retentionPaidPrev,
    //       totalISRIncomeAutorized:totalISRIncomeAutorized,
    //     }
    //   }
    // })
  }, [authorizationPrev, month, setFormValuesDetailsSummary, year])

  const handleInputChange = ({ target }) => {

    if (target.name === 'expensesAccumulatedsTotal') {
      let expenseAutorized = 0.00;
      if (taxpayerProfession === 'Médico/Dentista') {
        expenseAutorized = total;
      } else {
        expenseAutorized = subtotalExpenseAutorized;
      }
      let expensesAccumulatedTotal = (parseFloat(target.value) - parseFloat(expenseAutorized)).toFixed(2);

      setFormValuesDetailsSummary(prevState => {
        return {
          ...prevState,
          taxISRHonorarium: {
            ...prevState.taxISRHonorarium,
            expensesAccumulateds: expensesAccumulatedTotal
          }
        }
        return
      })

    }
    setFormValuesDetailsSummary(prevState => {
      return {
        ...prevState,
        taxISRHonorarium: {
          ...prevState.taxISRHonorarium,
          [target.name]: target.value
        }
      }
    })
  }

  return (
    <Fragment>
      <tr>
        <td className="w-50">Ingresos del mes de {nameMonth} {year}</td>
        <td className="w-50"><NumberFormat value={parseFloat(incomesSubtotalAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Ingresos acumulados hasta {nameMonth} {year}</td>
        <td className="w-50">
          {
            status === 'Presentada' ?
              (<TextField
                label="Ingresos acumulados" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="number"
                name="incomesAccumulatedsTotal"
                value={incomesAccumulatedsTotal}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
                disabled={true}
              />)
              :
              (<TextField
                label="Ingresos acumulados" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="number"
                name="incomesAccumulatedsTotal"
                value={incomesAccumulatedsTotal}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />)
          }
        </td>
      </tr>
      <tr>
        <td className="w-50">Gastos del mes de {nameMonth} {year}</td>
        <td className="w-50"><NumberFormat value={parseFloat(expensesSubtotalAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <tr>
        <td className="w-50">Gastos acumulados hasta {nameMonth} {year}</td>
        <td className="w-50">
          {
            status === 'Presentada' ?
              (
                <TextField
                  label="Egresos acumulados" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                  type="number"
                  name="expensesAccumulatedsTotal"
                  value={expensesAccumulatedsTotal}
                  onChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        $
                      </InputAdornment>
                    ),
                  }}
                  disabled={true}
                />
              )
              :
              (<TextField
                label="Egresos acumulados" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                type="number"
                name="expensesAccumulatedsTotal"
                value={expensesAccumulatedsTotal}
                onChange={handleInputChange}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      $
                    </InputAdornment>
                  ),
                }}
              />)
          }
        </td>
      </tr>

      <tr>
        <td className="w-50">Depreciación de activos fijos</td>
        <td className="w-50"><NumberFormat value={parseFloat(depresiationFixedAsset).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} />
          <button data-toggle="tooltip"
            data-placement="top"
            title="Agregar depreciación" className="btn btn-sm btn-outline-primary float-right align-middle"
            onClick={() => handleModal(
              <Depresiations
                formValuesDetailsSummary={formValuesDetailsSummary}
              />
            )}>
            <i className="fas fa-plus"></i> Agregar
          </button>
        </td>
      </tr>

      <tr>
        <td className="w-50">Depreciación de activos fijos acumulada</td>
        <td className="w-50"><NumberFormat value={parseFloat(depresiationAcumulated).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Base gravable</td>
        <td className="w-50"><NumberFormat value={parseFloat(taxableBaseTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Limite inferior</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.lowerLimit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Excedente del limite inferior</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.surplusLowerLimit).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /> </td>
      </tr>

      <tr>
        <td className="w-50">Porcentaje</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.porcentage * 100).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'%'} /></td>
      </tr>

      <tr>
        <td className="w-50">Impuesto marginal</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.marginalTax).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Cuota Fija</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.fixedFee).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Impuestos antes de retenciones</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.taxeBeforeRetentions).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">ISR de retenciones</td>
        <td className="w-50"><NumberFormat value={parseFloat(totalISRIncomeAutorized).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>

      <tr>
        <td className="w-50">Retenciones de meses anteriores</td>
        <TextField
          label="Retenciones de meses anteriores" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="number"
          name="retentionPrevMonths"
          value={retentionPrevMonths}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                $
              </InputAdornment>
            ),
          }}
          disabled={status === 'Presentada'}
        />
        {/* <td className="w-50"><NumberFormat value={parseFloat(retentionPrevMonths).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
      </tr>

      <tr>
        <td className="w-50">ISR pagado de meses anteriores</td>
        <TextField
          label="ISR pagado de meses anteriores" variant="outlined" size="small" autoComplete="off" fullWidth={true}
          type="number"
          name="retentionPaidPrev"
          value={retentionPaidPrev}
          onChange={handleInputChange}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                $
              </InputAdornment>
            ),
          }}
          disabled={status === 'Presentada'}
        />
        {/* <td className="w-50"><NumberFormat value={parseFloat(retentionPaidPrev).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td> */}
      </tr>

      <tr>
        <td className="w-50">ISR a pagar</td>
        <td className="w-50"><NumberFormat value={parseFloat(ISR.retentionToPay).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={'$'} /></td>
      </tr>
      <DepresiationsTable
        formValuesDetailsSummary={formValuesDetailsSummary}
      />

    </Fragment>
  )
}
