import { IconButton, InputAdornment, TextField } from "@material-ui/core"
import { Visibility, VisibilityOff } from "@material-ui/icons";
import { useParams } from 'react-router';
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateEmailToken } from "../../../../../actions/crm/preSales";
import { useForm } from "../../../../../hooks/useForm";
import validator from 'validator';
import { useHistory } from 'react-router';
import { CRM_SALES } from '../../../../constants/Routes';

export const EmailToken = () => {
    
    let history = useHistory();
    
    const { user } = useSelector(state => state.auth);
    const dispatch = useDispatch()
    const { crmSalePreSelectedInfo } = useSelector(state => state.crmPreSale);
    // const { token } = useParams();
    const [msgErrors, setMsgErrors] = useState({});

    const { formValues, handleInputChangedByToken } = useForm({
        tokenText: '',
        emailModal: '',
        emailConfirmModal: '',
    }
    ); 
    const { tokenText, emailModal, emailConfirmModal } = formValues;
    
    const handleSendPassword = ( e ) => {
        e.preventDefault();
        if( isFormValid() ){
            dispatch(updateEmailToken( crmSalePreSelectedInfo.token, emailModal, tokenText ))
        }
    }

    const isFormValid = () => {
        const errors = {}
        if (tokenText.trim().length < 10) {
            errors['msgTokenText'] = "Faltan digitos del Token"
        }
        if(!validator.isEmail(emailModal)){
            errors['msgEmail'] = 'Correo electrónico no es valido.';
        }
        if (emailModal !== emailConfirmModal) {
            errors['msgEmailConfirm'] = 'Los correos no coinciden.';
        }

        setMsgErrors( errors );

        if(Object.keys(errors).length !== 0 ){
            return false;
          }
      
        return true;
    }

    return (
        <div>
            <div className="col-12 mb-3">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <label> Favor de introducir el Token</label>
                    <TextField
                        label="Token" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        name="tokenText"
                        onChange={ handleInputChangedByToken }
                        helperText={ msgErrors.msgTokenText }
                        error={ msgErrors.msgTokenText !== undefined }
                    >         
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <label> Favor de introducir el nuevo Correo Electronico</label>
                    <TextField
                        label="Correo Electronico" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        name="emailModal"
                        onChange={ handleInputChangedByToken }
                        helperText={ msgErrors.msgEmail }
                        error={ msgErrors.msgEmail !== undefined }
                    >         
                    </TextField>
                </div>
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <TextField
                        label="Confirmar Correo Electronico" variant="outlined" size="small" autoComplete="off" fullWidth={true}
                        name="emailConfirmModal"
                        onChange={ handleInputChangedByToken }
                        helperText={ msgErrors.msgEmailConfirm }
                        error={ msgErrors.msgEmailConfirm !== undefined }
                    >         
                    </TextField>
                </div>
                <form onSubmit={ handleSendPassword }>
                    <button 
                        className="btn float-right btn-info mt-3"
                    > 
                        Enviar  
                    </button>
                </form>
            </div>
        </div>
  )
}
