import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { preAutorizedReportUpdateStart } from '../../../../../../../../actions/accounting/preAuthorizedReports';
import { clasificateExpenses, clasificateIncomes, consideredsExpenses, consideredsIncomes } from '../../../../../../../../helpers/clasificateDownloadAccounting';
import { ModalContext } from '../../../../../../../modal/ModalContext';
import { InformationDiot } from './InformationDiot';
import { IsrTaxCalculation } from './IsrTaxCalculation';
import { IvaTaxCalculation } from './IvaTaxCalculation';

const TaxCalculation = ({ autorizedReport = false, formValuesDetailsSummary, setFormValuesDetailsSummary, status,  refreshComplementPayments, activeTab, setactiveTab }) => {
  
  const dispatch = useDispatch();
  const { handleModal } = useContext(ModalContext);

  const { authorizationReport, authorizations } = useSelector(state => state.authorizationReport);
  const { incomes, statusIncomeDownload } = useSelector(state => state.income);
  const { expenses ,statusExpenseDownload} = useSelector(state => state.expense);
  const { expenseTypes } = useSelector(state => state.expenseType)
  const { retentions } = useSelector(state => state.retention);

  const { informationDiots, regimeSatCode, taxISRHonorarium, taxIVAHonorarium, taxISRLease, taxISRRIF,
          taxIVALease, taxIVARIF, taxISRPlataformsTechnologies, taxIVAPlataformsTechnologies, reportId,
          taxISRSimplified, taxIVASimplified, subtotalIncomesAutorized, totalExpensePersonalAutorized,
          subtotalExpenseNotAutorized, subtotalExpenseAutorized, totalIVATraslatedIncomeAutorized,
          totalIVARetainedIncomeAutorized, totalISRIncomeAutorized, totalIncomesAutorized, totalIVAExpenseAutorized,
          totalExpenseAutorized, month, year  } = formValuesDetailsSummary;

  const { ivaCaused, ivaExpenseTotal, ivaPeriodsPrevius, ivaToDeclared, optionIva, retentionPlataform, totalIva } = taxIVAPlataformsTechnologies;
  const { serviceAlienation, serviceGround, serviceLodging, totalIncomes, totalForIntermediaries, totalForUsers } = taxISRPlataformsTechnologies;

  const {  isBlindDeduction, blindDeduction, blindPorcentage, depresiationFixedAsset, ISR,  subtotalPredialExpense, taxableBase, totalISR, TotalISRRetained } = taxISRLease

  const { IVAMonthsPrev, subtotalIncome16, subtotalIncome0, subtotalIncomeExento, subtotalIVA, totalIvaRetained, totalIvaCreditable, totalTaxe16, totalIVA } = taxIVAHonorarium;
  
  const isrTotalCost = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const subtotalISR = parseFloat(subtotalExpenseAutorized) + parseFloat(isrTotalCost) 

  const considered = authorizations.paymentComplementExpensesSubtotal || authorizationReport.paymentComplementExpensesSubtotal || 0
  const ivaTotalAuth = authorizations.paymentComplementExpensesIva || authorizationReport.paymentComplementExpensesIva || 0

  const consideredExpenseTotalAcreditable = parseFloat(subtotalExpenseAutorized)  + parseFloat(considered)

  const consideredIvaAcreditable606 = parseFloat(taxIVALease.totalIVAExpense)  + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable612 = parseFloat(taxIVAHonorarium.totalIvaCreditable)  + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable621 = parseFloat(taxIVARIF.ivaCreditable)  + parseFloat(ivaTotalAuth)
  const consideredIvaAcreditable626 = parseFloat(totalIVAExpenseAutorized)  + parseFloat(ivaTotalAuth)
  
  const ivaExpenseTotalAcreditable625 = parseFloat( ivaExpenseTotal ) + parseFloat(ivaTotalAuth);
  const [totalIsrState, setTotalIsrState] = useState(0.00);

  const complementsData = authorizations.complementsExpenses || authorizationReport.complementsExpenses || [];
  // const handleResetCalculation = () => {
  //   if( status == 'En Proceso' || status == 'ReOpen' || status == 'Por Presentar') {
  //     refreshComplementPayments()
  //     let petition = {
  //       sendReport: false,
  //       reportId: reportId,
  //       isAuthorized: false
  //     }
  //     let res = true
  //     if(regimeSatCode === '625'){
  //       petition.isrJson =  JSON.stringify({});
  //       petition.ivaJson = JSON.stringify({});
  //       petition.Resume = JSON.stringify({});
  //     }
  //     if(regimeSatCode === '606'){
  //       petition.isrJson =  JSON.stringify({});
  //       petition.ivaJson = JSON.stringify({});
  //       petition.Resume = JSON.stringify({});
  //     }
  //     if (regimeSatCode === '621') {
  //       petition.isrJson =  JSON.stringify({});
  //       petition.ivaJson = JSON.stringify({});
  //       petition.Resume = JSON.stringify({});
  //     }
  //     if (regimeSatCode === '612') {
  //       petition.isrJson =  JSON.stringify({});
  //       petition.ivaJson = JSON.stringify({});
  //       petition.Resume = JSON.stringify({});
  //     }
  //     if (regimeSatCode === '626') {
  //       petition.isrJson =  JSON.stringify({});
  //       petition.ivaJson = JSON.stringify({});
  //       petition.Resume = JSON.stringify({}); 
  //     }
  //     dispatch(preAutorizedReportUpdateStart(petition, res));
  //   }
  // }

  useEffect(() => {
    let totalIsr = parseFloat(
      parseFloat(serviceAlienation.totalIsr) +
      parseFloat(serviceGround.totalIsr) +
      parseFloat(serviceLodging.totalIsr)
    ).toFixed(2)

    if (parseFloat(totalIsr) < 0) {
      totalIsr = 0.00;
    }
    setTotalIsrState(totalIsr)
  }, [serviceAlienation, serviceGround, serviceLodging, setTotalIsrState])

  const reumenFunction = () => {
    let flag = ''
    let JsonResume = {}

    let consideredsInc = []
    let consideredsExp = []
  
    let incomesUUID = ''
    let expensesUUID = ''
    let retentionsUUID = ''

    let complementsExpensesUUID = ''

    let expensesAutorized = ''
    let incomesAutorized = ''
    let retentionsAutorized = ''

    // let IncomesComplementsAutorized= ''
    let ExpensesComplementsAutorized = ''
    
    // FOrmulas para los ingresos considerados
    const clasificationsIncomes = clasificateIncomes( incomes, month, regimeSatCode, year);
    if ( clasificationsIncomes.length > 0) {
      consideredsInc = consideredsIncomes(clasificationsIncomes);
      incomesUUID = consideredsInc.map( data => {
        return data.uuid
      })
      incomesAutorized = incomesUUID.join('|').replace(/\[|\]/g, '');
    }
    
    // FOrmulas para las Deducciones autorizadas
    const clasificationsExpenses = clasificateExpenses(expenseTypes, expenses, month, regimeSatCode, year);
    if ( clasificationsExpenses.length > 0) {
      consideredsExp = consideredsExpenses(expenseTypes, clasificationsExpenses, regimeSatCode);
      expensesUUID = consideredsExp.map( data => {
        return data.uuid
      })
      expensesAutorized = expensesUUID.join('|').replace(/\[|\]/g, '');
    }

    // FOrmulas para las Retenciones
    if ( retentions.length > 0 ) {
      retentionsUUID = retentions.map( data => {
        return data.uuid
      })
      retentionsAutorized = retentionsUUID.join('|').replace(/\[|\]/g, '');
    }

     // FOrmulas para los complementos de gastos autorizadas
    if ( complementsData.length > 0) {

      const complementsDetails = complementsData.map(detail => detail.details) || []
      const detailWithByCalculate = complementsDetails.filter( detail => detail )
     
      const allObjetos = detailWithByCalculate.flat();
      const objetsFiltrados = allObjetos.filter(objeto => objeto.byCalculate === true);
      const uuid = objetsFiltrados.map(objeto => objeto.uuid);

      ExpensesComplementsAutorized = uuid.join('|').replace(/\[|\]/g, '');
    }
    
    if ( regimeSatCode === '625' ) {
      (taxIVAPlataformsTechnologies.totalIva >= 0) ? flag = false : flag = true 

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": totalIncomes,
          "DeduccionCiega": 0,
    
          "IngresosPorServicioTerrestre": serviceGround.totalService,
          "IngresosPorServicioHospedaje": serviceLodging.totalService,
          "IngresosPorEnajenacion": serviceAlienation.totalLendingPlataform,
          
          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,
    
          "TotalIva": taxIVAPlataformsTechnologies.totalIva,
          "TotalIsr": totalIsrState,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {
    
          "TotalIvaJson": {
    
            "IngresosMedianteIntermediarios": totalForIntermediaries,
            "IngresosMedianteUsuario": totalForUsers,
            "IngresosTotales": totalIncomes,
            "Tasa": optionIva.porcentage * 100,
            "IVACausado": ivaCaused,
            "IVAGastosAcreditable": ivaExpenseTotalAcreditable625,
            "RetencionesDeIVAPorPlataformaTecnologica": retentionPlataform,
            "IVADelPeriodoADeclarar": taxIVAPlataformsTechnologies.ivaToDeclared,
            "IVAAAcreditarDePeriodosAnteriores": ivaPeriodsPrevius,
    
            "TotalIva":taxIVAPlataformsTechnologies.totalIva,
            "IsAFavor":flag
          },
          "TotalIsrJSon": {
            "ServicioTerrestre":{
              "IngresosIntermediariosServicioDePasajeros": serviceGround.totalPassengerPlataform,
              "IngresosIntermediariosEntregaBienes": serviceGround.totalDealerPlataform,
              "IngresosDirectosServicioDePasajeros": serviceGround.totalServicePassengersFinal,
              "IngresosDirectosEntregaBienes": serviceGround.totalServiceDealerFinal,
              "IngresosTotales": serviceGround.totalService,
              "Tasa": serviceGround.ISR.porcentage * 100,
              "ISRCausado": serviceGround.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceGround.totalRetained,
              "ISRACargo": serviceGround.totalIsr,
            },
            "ServicioHospedaje":{
              "IngresosPorIntermediarios": serviceLodging.totalLodgingPlataform,
              "IngresosDirectos": serviceLodging.totalServiceLodgingFinal,
              "IngresosTotales": serviceLodging.totalService,
              "Tasa": serviceLodging.ISR.porcentage * 100,
              "IsrCausado": serviceLodging.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceLodging.totalRetained,
              "ISRACargo":serviceLodging.totalIsr,
            },
            "EnajenacionYPrestacionServicios":{
              "IngresosIntermediariosEnajenacion": serviceAlienation.totalAlienationPlataform,
              "IngresosIntermediariosPrestacionServicios": serviceAlienation.totalLendingPlataform,
              "IngresosDirectosEnajenacion": serviceAlienation.totalServiceAlienationFinal,
              "IngresosDirectosPrestacionServicios": serviceAlienation.totalServiceLendingFinal,
              "IngresosTotalesMenosRetenciones": serviceAlienation.totalService,
              "Tasa": serviceAlienation.ISR.porcentage * 100,
              "ISRCausado": serviceAlienation.ISR.isrCaused,
              "RetencionesPorPlataformasTecnologicas": serviceAlienation.totalRetained,
              "ISRACargo": serviceAlienation.totalIsr,
            }
          },
          
          "TotalesIncomesJson":{
            "Subtotal": subtotalIncomesAutorized,
            "IVATrasladado": totalIVATraslatedIncomeAutorized,
            "IVARetenido": totalIVARetainedIncomeAutorized,
            "ISRRetenido": totalISRIncomeAutorized,
            "TotalRecibido": totalIncomesAutorized
          },
    
          "TotalesExpensesJson":{
            "Subtotal": subtotalExpenseAutorized,
            "IVA": totalIVAExpenseAutorized,
            "Total": totalExpenseAutorized    
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized, 
        "IncomesComplementsAutorized": '',
        "ExpensesComplementsAutorized": ExpensesComplementsAutorized,
      }
    }
    if ( regimeSatCode === '606' ) {
      ( taxIVALease.totalIVA >= 0) ? flag = false : flag = true  

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": blindDeduction,
    
          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,
          
          "DeduccionesAutorizadas": 0,
          "DeduccionesNoAutorizadas": 0,
          "DeduccionesPersonales": totalExpensePersonalAutorized,
    
          "TotalIva": taxIVALease.totalIVA,
          "TotalIsr": taxISRLease.totalISR,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {
    
          "TotalIvaJson": {
            "IvaCausado":taxIVALease.totalIvaTraslated,
            "IvaRetenido":taxIVALease.totalRateinedIncomeIVA,
            "IvaAcreditable":consideredIvaAcreditable606,
            "IvaFavorPeriodosAnteriores":taxIVALease.IVAMonthsPrev,
            "TotalIva":taxIVALease.totalIVA,
            "IsAFavor":flag
          },
          "TotalIsrJSon": {
            "IsCiega":isBlindDeduction,
            "DeduccionGasgos":subtotalISR,
            "ImpuestoPredial":subtotalPredialExpense,
            "DeduccionCiega":blindDeduction,
            "DepreciacionActivosFijos":depresiationFixedAsset,
            "IngresosMes":subtotalIncomesAutorized,
            "BaseGravable":taxableBase,
            "LimiteInferior":taxISRLease.ISR.lowerLimit,
            "TotalGravable":taxISRLease.ISR.subtractLimitLower,
            "Tasa":taxISRLease.ISR.porcentage * 100,
            "TotalTasa":taxISRLease.ISR.forPorcentage,
            "CuotaFija":taxISRLease.ISR.fixedFee,
            "Acargo":taxISRLease.ISR.plusFixedFee,
            "IsrRetenido":TotalISRRetained,
            "TotalISR":taxISRLease.totalISR,
            "IsAFavor":false
          },
          "TotalesIncomesJson":{
            "Subtotal":subtotalIncomesAutorized,
            "IVATrasladado":totalIVATraslatedIncomeAutorized,
            "IVARetenido":totalIVARetainedIncomeAutorized,
            "ISRRetenido":totalISRIncomeAutorized,
            "TotalRecibido":totalIncomesAutorized
          },
          "TotalesExpensesJson":{
            "Subtotal":subtotalExpenseAutorized,
            "IVA":totalIVAExpenseAutorized,
            "Total":totalExpenseAutorized    
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized,
        "IncomesComplementsAutorized": '',
        "ExpensesComplementsAutorized": ExpensesComplementsAutorized,
      }
    }
    if ( regimeSatCode === '612' ) {
      ( taxIVAHonorarium.totalIVA >= 0) ? flag = false : flag = true  
      
      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,
  
          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,
          
          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,
  
          "TotalIva": taxIVAHonorarium.totalIVA,
          "TotalIsr": taxISRHonorarium.ISR.retentionToPay,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {
  
          "TotalIvaJson": {
  
            "ActividadesGravadasALaTasaDel16":subtotalIncome16,
            "ActividadesGravadasALaTasaDel0":subtotalIncome0,
            "ActividadesExentas":subtotalIncomeExento,
            "IVACobradoDelPeriodoALaTasaDel16":totalTaxe16,
            "IVAAcreditableDelPeriodo":consideredIvaAcreditable612,
            "IvaRetenido":totalIvaRetained,
            "IvaAFavorDePeriodosAnteriores":IVAMonthsPrev,
  
            "TotalIva":taxIVAHonorarium.totalIVA,
            "IsAFavor":flag
          },
          "TotalIsrJSon": {
  
            "IngresosDelMes":taxISRHonorarium.incomesSubtotalAutorized,
            "IngresosAcumuladosHasta":taxISRHonorarium.incomesAccumulatedsTotal,
            "GastosDelMes":subtotalISR,
            "GastosAcumuladosHasta":taxISRHonorarium.expensesAccumulatedsTotal,
            "DepreciacionDeActivosFijos":taxISRHonorarium.depresiationFixedAsset,
            "DepreciacionDeActivosFijosAcumulada":taxISRHonorarium.depresiationAcumulated,
            "BaseGravable":taxISRHonorarium.taxableBaseTotal,
            "LimiteInferior":taxISRHonorarium.ISR.lowerLimit,
            "ExcedenteDelLimiteInferior":taxISRHonorarium.ISR.surplusLowerLimit,
            "Porcentaje":taxISRHonorarium.ISR.porcentage * 100,
            "ImpuestoMarginal":0,
            "CuotaFija":taxISRHonorarium.ISR.fixedFee,
            "ImpuestosAntesDeRetenciones":taxISRHonorarium.ISR.taxeBeforeRetentions,
            "IsrDeRetenciones":taxISRHonorarium.totalISRIncomeAutorized,
            "RetencionesDeMesesAnteriores":taxISRHonorarium.retentionPrevMonths,
            "IsrPagadoDeMesesAnteriores":taxISRHonorarium.retentionPaidPrev,
  
            "TotalISR":taxISRHonorarium.ISR.retentionToPay,
            "IsAFavor":false
  
          },
            
          "TotalesIncomesJson":{
            "Subtotal":subtotalIncomesAutorized,
            "IVATrasladado":totalIVATraslatedIncomeAutorized,
            "IVARetenido":totalIVARetainedIncomeAutorized,
            "ISRRetenido":totalISRIncomeAutorized,
            "TotalRecibido":totalIncomesAutorized
          },
  
          "TotalesExpensesJson":{
            "Subtotal":subtotalExpenseAutorized,
            "IVA":totalIVAExpenseAutorized,
            "Total":totalExpenseAutorized    
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized,
        "IncomesComplementsAutorized": '',
        "ExpensesComplementsAutorized": ExpensesComplementsAutorized,
      }
    }
    if ( regimeSatCode === '621' ) {
      ( taxIVARIF.totalIva >= 0) ? flag = false : flag = true 

      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,
  
          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,
          
          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": totalExpensePersonalAutorized,
  
          "TotalIva": taxIVARIF.totalIva,
          "TotalIsr": taxISRRIF.totalIsr,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {
  
          "TotalIvaJson": {
  
            "IngresosPorVentasAlPublicoEnGeneral": taxIVARIF.subtotalIncomesPublicGeneral,
            "IngresosFacturadosALaTasaDel16": taxIVARIF.subtotalIncomes16,
            "IngresosFacturadosALaTasaDel0": taxIVARIF.subtotalIncomes0,
            "IngresosExentos": taxIVARIF.subtotalIncomesExento,
            "ComprasYGastosPagadosConTasa16": taxIVARIF.subtotalExpense16,
            "ComprasYGastosPagadosEnLaRegionFronteriza": taxIVARIF.subtotalExpenseForeign,
            "ComprasYGastosPagadosALaTasaDel0": 0,
            "ProporcionUtilizadaConformeALaLIVA": taxIVARIF.totalLIVA,
            "IVARetenido": taxIVARIF.ivaRetained,
            "IVAPorPublicoEnGeneral": taxIVARIF.ivaPublicGeneral,
            "PorcentajeReduccion": taxIVARIF.deductionIsr * 100,
            "Reduccion": taxIVARIF.ivaReduced,
            "IVACobrado": taxIVARIF.ivaCharged,
            "IVAAcreditable": consideredIvaAcreditable621,
            "IVAACargo": taxIVARIF.ivaToCharge,
            "IVAAFavorDePeriodosAnteriores": taxIVARIF.IVAMonthsPrev,
  
            "TotalIva":taxIVARIF.totalIva,
            "IsAFavor":flag
          },
          "TotalIsrJSon": {
  
            "IngresosDelMes":subtotalIncomesAutorized,
            "GastosDelMes":subtotalISR,
            "DiferenciaPorGastosMayores":taxISRRIF.expensesHigherToIncome,
            "Utilidad":taxISRRIF.utility,
            "DiferenciaPorGastosMayoresPrevios":taxISRRIF.expensesHigherToIncomePrev,
            "BaseParaCalculo":taxISRRIF.calculatedBase,
            "LimiteInferior":taxISRRIF.lowerLimit,
            "ExcendeDelLimite":taxISRRIF.subtotalIncomes,
            "Tasa":taxISRRIF.ISR.porcentage * 100,
            "ISRMarginal":taxISRRIF.isrMarginal,
            "CuotaFija":taxISRRIF.ISR.fixedFee,
            "ISRDeterminado":taxISRRIF.isrDetermined,
            "PorcentajeReducción":taxISRRIF.deductionIsr * 100,
            "Reduccion":taxISRRIF.reductionAmount,
  
            "TotalISR":taxISRRIF.totalIsr,
            "IsAFavor":false
  
          },
            
          "TotalesIncomesJson":{
            "Subtotal":subtotalIncomesAutorized,
            "IVATrasladado":totalIVATraslatedIncomeAutorized,
            "IVARetenido":totalIVARetainedIncomeAutorized,
            "ISRRetenido":totalISRIncomeAutorized,
            "TotalRecibido":totalIncomesAutorized
          },
  
          "TotalesExpensesJson":{
            "Subtotal":subtotalExpenseAutorized,
            "IVA":totalIVAExpenseAutorized,
            "Total":totalExpenseAutorized    
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized,
        "IncomesComplementsAutorized": '',
        "ExpensesComplementsAutorized": ExpensesComplementsAutorized,
      }
    }
    if ( regimeSatCode === '626' ) {
      ( taxIVASimplified.totalIVA >= 0) ? flag = false : flag = true  
      
      JsonResume = {
        "ImportsHeaders": {
          "Ingresos": subtotalIncomesAutorized,
          "DeduccionCiega": 0,
    
          "IngresosPorServicioTerrestre": 0,
          "IngresosPorServicioHospedaje": 0,
          "IngresosPorEnajenacion": 0,
          
          "DeduccionesAutorizadas": consideredExpenseTotalAcreditable,
          "DeduccionesNoAutorizadas": subtotalExpenseNotAutorized,
          "DeduccionesPersonales": 0,
    
          "TotalIva": taxIVASimplified.totalIVA,
          "TotalIsr": taxISRSimplified.ISR.retentionToPay,
          "IsAFavor": flag
        },
        "TotalesImpuestos": {
    
          "TotalIvaJson": {
            "ActividadesGravadasALaTasaDel16": taxIVASimplified.subtotalIncome16,
            "ActividadesGravadasALaTasaDel0": taxIVASimplified.subtotalIncome0,
            "ActividadesExentas": taxIVASimplified.subtotalIncomeExento,
            "IVACobradoDelPeriodoALaTasaDel16": taxIVASimplified.totalTaxe16,
            "IVAAcreditableDelPeriodo": consideredIvaAcreditable626,
            "IvaRetenido": taxIVASimplified.totalIvaRetained,
            "IvaAFavorDePeriodosAnteriores": taxIVASimplified.IVAMonthsPrev,
    
            "TotalIva": taxIVASimplified.totalIVA,
            "IsAFavor ": flag
          },
          "TotalIsrJSon": {
            "IngresosDelMes": taxISRSimplified.incomesSubtotalAutorized,
            "Tasa": taxISRSimplified.ISR.porcentage,
            "ImpuestosAntesDeRetenciones": taxISRSimplified.ISR.taxeBeforeRetentions,
            "IsrDeRetenciones": taxISRSimplified.ISR.isrRetentions,
    
            "TotalISR": taxISRSimplified.ISR.retentionToPay,
            "IsAFavor": false
          },
          "TotalesIncomesJson":{
            "Subtotal":subtotalIncomesAutorized,
            "IVATrasladado":totalIVATraslatedIncomeAutorized,
            "IVARetenido":totalIVARetainedIncomeAutorized,
            "ISRRetenido":totalISRIncomeAutorized,
            "TotalRecibido":totalIncomesAutorized
          },
          "TotalesExpensesJson":{
            "Subtotal":subtotalExpenseAutorized,
            "IVA":totalIVAExpenseAutorized,
            "Total":totalExpenseAutorized    
          }
        },
        "IncomesAutorized": incomesAutorized,
        "ExpensesAutorized": expensesAutorized,
        "Retentions": retentionsAutorized,  
        "IncomesComplementsAutorized": '',
        "ExpensesComplementsAutorized": '',
      }   
    }

    return JsonResume;
  }

  useEffect(() => {
    if(regimeSatCode === '625'){
      let totalIntermediaries = (
        parseFloat(serviceAlienation.totalForIntermediaries) +
        parseFloat(serviceGround.totalForIntermediaries) +
        parseFloat(serviceLodging.totalForIntermediaries)
      ).toFixed(2);

      let totalUsers = (
        parseFloat(serviceAlienation.totalForUsers) +
        parseFloat(serviceGround.totalForUsers) +
        parseFloat(serviceLodging.totalForUsers)
      ).toFixed(2);

      let totalIncomes = (
        parseFloat(totalUsers) +
        parseFloat(totalIntermediaries)
      );

      setFormValuesDetailsSummary(prevState => {
        return{
          ...prevState,
          taxISRPlataformsTechnologies:{
            ...prevState.taxISRPlataformsTechnologies,
            totalIncomes: totalIncomes,
            totalForIntermediaries: totalIntermediaries,
            totalForUsers: totalUsers,
          }
        }
      })
    }
  }, [regimeSatCode, serviceAlienation, serviceGround, serviceLodging, setFormValuesDetailsSummary])

  const handleSaveCalculation = (sendReport ,e) => {
    e.preventDefault();
    refreshComplementPayments()
    const resumenPetition = reumenFunction();
    let petition = {
      sendReport: sendReport,
      reportId: reportId,
      isAuthorized: false,
      isFromToken: false 
    }
    if(regimeSatCode === '625'){
      petition.isrJson =  JSON.stringify(taxISRPlataformsTechnologies);
      petition.ivaJson = JSON.stringify(taxIVAPlataformsTechnologies);
      petition.Resume = JSON.stringify(resumenPetition);
    }
    if(regimeSatCode === '606'){
      petition.isrJson =  JSON.stringify(taxISRLease);
      petition.ivaJson = JSON.stringify(taxIVALease);
      petition.Resume = JSON.stringify(resumenPetition);
    }
    if (regimeSatCode === '621') {
      petition.isrJson =  JSON.stringify(taxISRRIF);
      petition.ivaJson = JSON.stringify(taxIVARIF);
      petition.Resume = JSON.stringify(resumenPetition);
    }
    if (regimeSatCode === '612') {
      petition.isrJson =  JSON.stringify(taxISRHonorarium);
      petition.ivaJson = JSON.stringify(taxIVAHonorarium);
      petition.Resume = JSON.stringify(resumenPetition);
    }
    if (regimeSatCode === '626') {
      petition.isrJson =  JSON.stringify(taxISRSimplified);
      petition.ivaJson = JSON.stringify(taxIVASimplified);
      petition.Resume = JSON.stringify(resumenPetition);
    }

    dispatch(preAutorizedReportUpdateStart(petition));
    refreshComplementPayments()
  }

  // const handleSaveCalculationAuto = () => {
  //   if( status == 'En Proceso' || status == 'ReOpen' || status == 'Por Presentar') {
  //     refreshComplementPayments()
  //     const resumenPetition = reumenFunction();
  //     let petition = {
  //       sendReport: false,
  //       reportId: reportId,
  //       isAuthorized: false,
  //       isFromToken: false 
  //     }
  //     if(regimeSatCode === '625'){
  //       petition.isrJson =  JSON.stringify(taxISRPlataformsTechnologies);
  //       petition.ivaJson = JSON.stringify(taxIVAPlataformsTechnologies);
  //       petition.Resume = JSON.stringify(resumenPetition);
  //     }
  //     if(regimeSatCode === '606'){
  //       petition.isrJson =  JSON.stringify(taxISRLease);
  //       petition.ivaJson = JSON.stringify(taxIVALease);
  //       petition.Resume = JSON.stringify(resumenPetition);
  //     }
  //     if (regimeSatCode === '621') {
  //       petition.isrJson =  JSON.stringify(taxISRRIF);
  //       petition.ivaJson = JSON.stringify(taxIVARIF);
  //       petition.Resume = JSON.stringify(resumenPetition);
  //     }
  //     if (regimeSatCode === '612') {
  //       petition.isrJson =  JSON.stringify(taxISRHonorarium);
  //       petition.ivaJson = JSON.stringify(taxIVAHonorarium);
  //       petition.Resume = JSON.stringify(resumenPetition);
  //     }
  //     if (regimeSatCode === '626') {
  //       petition.isrJson =  JSON.stringify(taxISRSimplified);
  //       petition.ivaJson = JSON.stringify(taxIVASimplified);
  //       petition.Resume = JSON.stringify(resumenPetition);
  //     }
  
  //     dispatch(preAutorizedReportUpdateStart(petition));
  //     refreshComplementPayments()
  //   }
  // }

  const handleResetCalculation = (sendReport ,e) => {
    e.preventDefault();
    refreshComplementPayments()
    let petition = {
      sendReport: sendReport,
      reportId: reportId,
      isAuthorized: false
    }
    let res = true
    if(regimeSatCode === '625'){
      petition.isrJson =  JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({});
    }
    if(regimeSatCode === '606'){
      petition.isrJson =  JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({});
    }
    if (regimeSatCode === '621') {
      petition.isrJson =  JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({});
    }
    if (regimeSatCode === '612') {
      petition.isrJson =  JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({});
    }
    if (regimeSatCode === '626') {
      petition.isrJson =  JSON.stringify({});
      petition.ivaJson = JSON.stringify({});
      petition.Resume = JSON.stringify({}); 
    }

    // console.log('petition', petition)
    // return false
    dispatch(preAutorizedReportUpdateStart(petition, res));
  }

  // useEffect(() => {
  //   if( activeTab == 'nav-taxCalculation') {
  //     handleResetCalculation()
  //     handleSaveCalculationAuto()
  //   }
  // }, [setactiveTab, activeTab])
  
  return (
    <div className="row">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="card">
          <div className="card-header">
            <div className="row">
              <div className="col-xs-8 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <h5>Fecha límite para pagar sin recargos:</h5>
              </div>

              {
                status !== 'Presentada' &&(
                  <div className="col-xs-4 col-sm-12 col-md-6 col-lg-6 col-xl-6 text-right">
                    <button type="button" className="btn btn-warning" onClick={handleResetCalculation.bind(this, false)}>
                      Reset calculo  
                    </button>
                    <button type="button" className="btn btn-success ml-3" onClick={handleSaveCalculation.bind(this, false)}>
                      Guardar calculo
                    </button>
                    
                    <button type="button" className="btn btn-primary ml-3" onClick={handleSaveCalculation.bind(this, true)}>
                      Enviar Reporte
                    </button>
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
        <div className="row">
          <IvaTaxCalculation 
            formValuesDetailsSummary={ formValuesDetailsSummary }
            regimeSatCode={ regimeSatCode }
            setFormValuesDetailsSummary ={ setFormValuesDetailsSummary }
            autorizedReport={autorizedReport}
            status={status}
          /> 

          <IsrTaxCalculation
            formValuesDetailsSummary={ formValuesDetailsSummary }
            regimeSatCode={ regimeSatCode }
            setFormValuesDetailsSummary ={ setFormValuesDetailsSummary }
            autorizedReport={autorizedReport}
            status={status}
          />
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
            <div className="row">
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                <h4>
                  <i className="fas fa-question"></i> Ayuda
                </h4>
                <hr />
                <p className="small">
                  Este mes no deberás pagar impuestos pero recuerda presentar tu aviso en ceros de ISR y manifestar tu saldo a favor de IVA mediante tu hoja de ayuda.
                </p>
                <small>
                  ¿Cómo calcular los pagos provisionales?
                </small>
                <hr />
                <small>
                  ¿Cómo realizar pagos provisionales en las bancas electrónicas?
                </small>
                <hr />
                <h5>
                  <i className="far fa-calendar-alt"></i> Fecha de pago
                </h5>
                <p className="small">
                  Si ya realizaste tu pago y/o presentaste tu declaración haz click aquí
                </p>
                <hr />
                <h5>
                  <i className="fas fa-receipt"></i> Comprobante de pago
                </h5>
                <p className="small">
                  Haz click aquí para adjuntar tu comprobante de pago
                </p>

                { regimeSatCode !== '621' &&
                  (
                    <Fragment>
                      <h5>
                        <i className="fas fa-receipt"></i> DIOT
                      </h5>
                      <p className="small"
                        onClick={() => handleModal(
                          <InformationDiot
                            informationDiots={informationDiots}
                          />, true
                        )}
                      >
                        <ins>Haz click aquí para descargar DIOT</ins>
                      </p>
                    </Fragment>
                  )
                }

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TaxCalculation;
