import React, { useEffect, useContext } from 'react';
import { Badge } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { startLogout } from '../../actions/auth';
import { ACCOUNTING, TAXPAYERS, REFERENCE_CODE, INVOICES, PAYROLL, BILLING, CRM_SALES, ADMIN, CRM_RENOVATIONS, ADMIN_SALES, ADMIN_REPORTS, ACCOUNT_REPORTS, ADMIN_ADMINISTRATORS, METRIC, CATALOG_EXPENSE_CLASSICATION, VIEW_CONFIG_CATALOGS, VIEW_CONFIG_NEWS } from '../constants/Routes';
import './Style.scss';
import { mailboxStartLoading } from '../../actions/mailbox/mailbox';
import { ModuleMailboxScreen } from './ModuleMailboxScreen';
import { ModalContext } from '../modal/ModalContext';
import { GenerarToken } from '../pages/crm/sales/PreSale/GenerarToken';
import { ModuleHelpers } from './ModuleHelpers';

export const NavbarCustomer = () => {

  const { user } = useSelector(state => state.auth);
  const { mailboxGet } = useSelector(state => state.mail);

  const { handleModal } = useContext(ModalContext);

  const { descriptionRole } = user;

  const dispatch = useDispatch();

  const handleLogout = (e) => {
    dispatch(startLogout());
  }

  useEffect(() => {
    dispatch(mailboxStartLoading());
  }, [dispatch]);

  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark bg-dark-contabox shadow animate__animated animate__fadeIn">
        <button className="navbar-toggler" type="button" data-toggle="collapse"
          data-target="#navbarContent" aria-controls="navbarContent"
          aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse" id="navbarContent">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item active">
              <p className="text-white pt-1 mb-0"> {user.userName} </p>
            </li>
          </ul>
          <ul className="navbar-nav aling-left">
            <li className="nav-item">
              <button
                className="btn btn-info btn-sm mr-2"
                onClick={() => handleModal
                  (
                    <ModuleHelpers />
                  )}>
                Manual de usuario
              </button>
            </li>
            <li className="nav-item">
              <button
                className="btn btn-info btn-sm"
                onClick={() => handleModal
                  (
                    <ModuleMailboxScreen />
                  )}>
                <Badge color="secondary" badgeContent={mailboxGet.length} showZero>
                  Buzón
                </Badge>
              </button>
            </li>
          </ul>
          {
            (user.isBoss == true && user.descriptionRole == "Seller") ?
              <li>
                <button
                  className='btn btn-warning btn-sm'
                  onClick={() => handleModal(
                    <GenerarToken />
                  )}
                >
                  Generar Token
                </button>
              </li>
              :
              ''
          }
          <ul>
          </ul>
          <ul className="navbar-nav aling-rigth">
            <li className="nav-item">
              <button className="btn btn-danger btn-sm" onClick={handleLogout}>
                Cerrar sesión <i className="fas fa-sign-out-alt"></i>
              </button>
            </li>
          </ul>
        </div>
      </nav>

      <div className="container-fluid my-3">
        <nav className="navbar navbar-expand-lg navbar-light bg-contabox shadow p-3-contabox mb-5 bg-body rounded-contabox animate__animated animate__fadeIn">
          <button className="navbar-toggler" type="button" data-toggle="collapse"
            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
            aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <p className="contabox-customer navbar-toggler">
            CONTABOX
          </p>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav text-header-landing mr-auto">
              {/* {
                user.descriptionRole === 'Administration' &&
                <li className="nav-item">
                  <Link to={ADMIN_ADMINISTRATORS} className="a-contabox">
                    <i className="fas fa-users"></i> Usuarios
                  </Link>
                </li>
              } */}
              {
                (descriptionRole === 'Administration' || descriptionRole === 'Service Customer') &&
                <li className="nav-item">
                  <Link to={ADMIN} className="a-contabox">
                    <i className="fas fa-user-secret"></i> Administración
                  </Link>
                </li>
              }

              {
                (descriptionRole === 'Seller' || descriptionRole === 'Service Customer') &&
                <li className="nav-item">
                  <Link to={CRM_SALES} className="a-contabox">
                    <i className="fas fa-shopping-cart"></i> Ventas
                  </Link>
                </li>
              }

              {
                (descriptionRole === 'Administration') &&
                <li className="nav-item">
                  <Link to={ADMIN_SALES} className="a-contabox">
                    <i className="fas fa-shopping-cart"></i> Ventas
                  </Link>
                </li>
              }

              {(user.descriptionRole === 'Administration' || descriptionRole === 'Service Customer') &&
                <li className="nav-item">
                  <Link to={ADMIN_REPORTS} className="a-contabox">
                    <i className="fas fa-file-signature"></i> Reportes
                  </Link>
                </li>
              }

              {user.descriptionRole === 'Accounter' &&
                <li className="nav-item">
                  <Link to={ACCOUNT_REPORTS} className="a-contabox">
                    <i className="fas fa-file-signature"></i> Reportes
                  </Link>
                </li>
              }
              <li className="nav-item">
                <Link to={ADMIN_ADMINISTRATORS} className="a-contabox">
                  <i className="fas fa-user-secret"></i> Usuarios
                </Link>
              </li>

              {user.descriptionRole === 'Accounter' &&
                <li className="nav-item">
                  <Link to={VIEW_CONFIG_CATALOGS} className="a-contabox">
                    <i className="fa fa-cog"></i> Configuración de Catálogos
                  </Link>
                </li>
              }
              {user.descriptionRole === 'Accounter' &&
                <li className="nav-item">
                  <Link to={VIEW_CONFIG_NEWS} className="a-contabox">
                    <i className="fa fa-newspaper"></i> Subir Noticias
                  </Link>
                </li>
              }


              {/* {
                (descriptionRole === 'Accounter') && user.isBoss &&
                <li className="nav-item">
                  <Link to={CATALOG_EXPENSE_CLASSICATION} className="a-contabox">
                    <i className="fas fa-ellipsis-v"></i> Clasificación Gastos
                  </Link>
                </li>
              } */}

              {
                (descriptionRole === 'Seller') &&
                <li className="nav-item">
                  <Link to={CRM_RENOVATIONS} className="a-contabox">
                    <i className="fas fa-cart-arrow-down"></i> Renovaciones
                  </Link>
                </li>
              }

              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={ACCOUNTING(null, null, null)} className="a-contabox">
                    <i className="fas fa-coins"></i> Contabilidad
                  </Link>
                </li>
              }

              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={TAXPAYERS} className="a-contabox">
                    <i className="fas fa-users"></i> Contribuyentes
                  </Link>
                </li>
              }

              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={INVOICES} className="a-contabox">
                    <i className="fas fa-file-import"></i> Facturas
                  </Link>
                </li>
              }

              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={PAYROLL} className="a-contabox">
                    <i className="fas fa-file-invoice-dollar"></i> Recibos de Nómina
                  </Link>
                </li>
              }
              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={BILLING} className="a-contabox">
                    <i className="fas fa-file-invoice-dollar"></i> Facturacion
                  </Link>
                </li>
              }
              {user.descriptionRole === 'Guest' &&
                <li className="nav-item">
                  <Link to={REFERENCE_CODE} className="a-contabox">
                    <i className="fas fa-file-invoice-dollar"></i> Código de Referencia
                  </Link>
                </li>
              }
              {user.descriptionRole === 'Service Customer' &&
                <li className="nav-item">
                  <Link to={METRIC} className="a-contabox">
                    <i className="fas fa-chart-line"></i> Métricas Mobile
                  </Link>
                </li>
              }
            </ul>
            <span className="navbar-text">
            </span>
          </div>
        </nav>
      </div>
    </div>
  )
}
