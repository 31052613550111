import { faCommentDollar } from '@fortawesome/free-solid-svg-icons'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import swal from 'sweetalert'
import { crmSaleOptainSelected, crmSaleSelectedClear, crmSaleStartUpdate, startRegisterCRMSale } from '../../../../actions/crm/sales'
import { crmReferenceRedPayFinish } from '../../../../actions/crm/subscriptions'
import { validateArrayPresence, validateNumberPresence, validateRfcValid, validateStringPresence,validateFilePresence,validatePhoneValid } from '../../../../helpers/validateForm'
import { useForm } from '../../../../hooks/useForm'
import { CRM_SALES } from '../../../constants/Routes'
import { ModuleAddressScreen } from './FormModules/ModuleAddressScreen'
import { ModuleCommentScreen } from './FormModules/ModuleCommentScreen'
import { ModuleCSDScreen } from './FormModules/ModuleCSDScreen'
import { ModuleFielScreen } from './FormModules/ModuleFielScreen'
import { ModuleFilesScreen } from './FormModules/ModuleFilesScreen'
import { ModuleInvoiceScreen } from './FormModules/ModuleInvoiceScreen'
import { ModulePaymentReference } from './FormModules/ModulePaymentReference'
import { ModuleSaleScreen } from './FormModules/ModuleSaleScreen'
import { ModuleTaxpayerScreen } from './FormModules/ModuleTaxpayerScreen'
import { ModuleUserScreen } from './FormModules/ModuleUserScreen'
import { ModuleVoucherScreen } from './FormModules/ModuleVoucherScreen'
export const CrmSalesFormScreen = () => {

  const {saleId} = useParams();

  const { regimes } = useSelector(state => state.regime);

  const { crmSaleSelected } = useSelector(state => state.crmSale);
  const { sesionSat } = useSelector(state => state.validationSat);

  const { referenceDataRedPay,referenceDataRedPayFinish } = useSelector(state => state.subscriptionSale);

  let history = useHistory();

  const dispatch = useDispatch();

  const [msgErrors, setMsgErrors] = useState({});
  const [accounting,setAccounting] = useState(true);
  const [invoice,setInvoice] = useState(false);
  const [errorCustomer,setErrorCustomer] = useState(false)
  const [errorSale,setErrorSale] = useState(false)
  const [errorTaxpayer,setErrorTaxpayer] = useState(false)
  const [errorAddress,setErrorAddress] = useState(false)
  const [errorVoucher,setErrorVoucher] = useState(false)

  const {formValues, setFormValues, handleCheckboxTrueFalseChange, handleInputChange} = useForm({
    address:{
      country: '',
      interior: '',
      outdoor: '',
      postalCode: '',
      street: '',
      suburb: '',
      town: ''
    },
    customerData: {
      email: '',
      emailNotification: '',
      isActive: true,
      idRole: 1,
      lastName: '',
      motherLastName: '',
      name: '',
      password: '',
      phoneMobile: '',
      socialMediaId: 0,
    },
    invoiceData: {
      cfdiUseId: 0,
      comments: '',
      receptorAccountId: 0,
      wayOfPaymentId: 0,
    },
    profileData:{
      bussinessName: '',
      passwordSAT: '',
      professionId: 0,
      regimes: [],
      rfc: '',
      serviceTypes: '',
      zoneTypes: '',
      phoneMobile: '',
      startSatAt: ''
    },
    taxCsdFiles:{},
    taxEfirmaFiles:{},
    TaxFilesInfo: [
      {
        DocumentFile: '',
        id: new Date().getTime(),
        DocumentTypeId: 0,
      }
    ],
    salesData:[
      {
        id: new Date().getTime(),
        price: 0.00,
        productId: 0,
      }
    ],
    voucher: {
      voucherFiles: '',
      paymentAt: '',
      isLoadedVoucher: false
    },
    taxPayer:{
      name: '',
      rfc: ''
    }, 
    // meetingFiel: '',
    comments: '',
    endServiceAt: moment().format('YYYY-MM-DD'),
    startServiceAt: moment().format('YYYY-MM-DD'),
    total: 0.00,
    InvoiceTaxPayer: false,
    isSubscription: false,
    Frecuency: '',
    GatewayId: 0,
    GatewayPaymentsId:0
  })

  const { address, comments, customerData, endServiceAt, invoiceData, taxPayer, InvoiceTaxPayer, isSubscription, Frecuency, GatewayId,
          profileData, salesData, startServiceAt, taxCsdFiles, taxEfirmaFiles, TaxFilesInfo, total, voucher,GatewayPaymentsId
        } = formValues;

  useEffect(() => {
    if (saleId !== undefined) {
      dispatch(crmSaleOptainSelected({id: saleId}))
    }else{
      dispatch(crmSaleSelectedClear())
    }
  }, [dispatch, saleId])

  useEffect(() => {
    if(crmSaleSelected){
      let products = [];
      let regimes = [];
      crmSaleSelected.products.forEach((product) =>{
        products.push({
          id: product.id,
          price: product.total,
          productId: product.product.id,
        })
      })

      crmSaleSelected.user.profiles[0].regimes.forEach((regime) => {
        regimes.push( parseInt(regime.regimeId))
      })

      setFormValues(prevState => {
        return{
          ...prevState,
          address:{
            ...prevState,
            country: crmSaleSelected.user.profiles[0].addresses[0].address.country || '',
            interior: crmSaleSelected.user.profiles[0].addresses[0].address.interior || '',
            outdoor: crmSaleSelected.user.profiles[0].addresses[0].address.outdoor || '',
            postalCode: crmSaleSelected.user.profiles[0].addresses[0].address.postalCode || '',
            street: crmSaleSelected.user.profiles[0].addresses[0].address.street || '',
            suburb: crmSaleSelected.user.profiles[0].addresses[0].address.suburb || '',
            town: crmSaleSelected.user.profiles[0].addresses[0].address.town || ''
          },
          customerData:{
            ...prevState.customerData,
            name: crmSaleSelected.user.name,
            lastName: crmSaleSelected.user.lastName,
            motherLastName: crmSaleSelected.user.motherLastName,
            email: crmSaleSelected.user.email,
            emailNotification: crmSaleSelected.user.emailNotification,
            phoneMobile: crmSaleSelected.user.phoneMobile
          },
          
          // invoiceData: {
          //   cfdiUseId: crmSaleSelected.invoiceData.cfdiUse.id,
          //   comments: crmSaleSelected.invoiceData.comments,
          //   receptorAccountId: crmSaleSelected.invoiceData.receptorAccount.id,
          //   wayOfPaymentId: crmSaleSelected.invoiceData.wayOfPaypment.id,
          // },
          profileData:{
            ...prevState.profileData,
            bussinessName: crmSaleSelected.user.profiles[0].businessName,  
            passwordSAT: crmSaleSelected.user.profiles[0].passwordSAT || '',
            regimes: regimes,
            rfc: crmSaleSelected.user.profiles[0].rfc,
            phoneMobile: crmSaleSelected.user.profiles[0].phoneMobile,
            startSatAt: moment(crmSaleSelected.user.profiles[0].startSatAt).format('YYYY-MM-DD'),
          },
          salesData: products,
          endServiceAt: moment(crmSaleSelected.endServiceAt).format('YYYY-MM-DD'),
          startServiceAt: moment(crmSaleSelected.startServiceAt).format('YYYY-MM-DD'),
          total: crmSaleSelected.total,
          voucher: {
            ...prevState.voucher,
            paymentAt: crmSaleSelected.paymentAt ? moment(crmSaleSelected.paymentAt).format('YYYY-MM-DD') : '',
            isLoadedVoucher: crmSaleSelected.voucherPaths ? true : false,
          },
          meetingFiel: crmSaleSelected.meetingFiel === "0001-01-01T00:00:00" ? '' :  moment(crmSaleSelected.meetingFiel).format('YYYY-MM-DD'),
          comments: crmSaleSelected.comments
        }
      })
    }
  }, [crmSaleSelected, setFormValues]);

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();

    Object.keys(invoiceData).forEach(function(key) {
      formData.append(`invoiceData.${key}`, invoiceData[key]);
    });

    let indexTaxFiles = 0;
    
    if(Object.keys(taxCsdFiles).length !== 0){
      formData.append(`TaxFiles[${indexTaxFiles}].Files`, taxCsdFiles['fileCer']);
      formData.append(`TaxFiles[${indexTaxFiles}].Files`, taxCsdFiles['fileKey']);
      formData.append(`TaxFiles[${indexTaxFiles}].Password`, taxCsdFiles['password']);
      formData.append(`TaxFiles[${indexTaxFiles}].IsFiel`, taxCsdFiles['isFiel']);
      indexTaxFiles += 1;
    }

    if(Object.keys(taxEfirmaFiles).length !== 0){
      formData.append(`TaxFiles[${indexTaxFiles}].Files`, taxEfirmaFiles['fileCer']);
      formData.append(`TaxFiles[${indexTaxFiles}].Files`, taxEfirmaFiles['fileKey']);
      formData.append(`TaxFiles[${indexTaxFiles}].Password`, taxEfirmaFiles['password']);
      formData.append(`TaxFiles[${indexTaxFiles}].IsFiel`, taxEfirmaFiles['isFiel']);
      indexTaxFiles += 1;
    }

    if (voucher.voucherFiles !== '' && voucher.paymentAt !== '') {
      formData.append(`IsSold`, true);
      formData.append(`voucherFiles`, voucher.voucherFiles);
      formData.append(`paymentAt`, voucher.paymentAt);
    }

    formData.append("StartServiceAt", startServiceAt);
    formData.append("EndServiceAt", endServiceAt);
    formData.append(`comments`, comments);
    formData.append(`total`, total);
    formData.append(`InvoiceTaxPayer`, InvoiceTaxPayer);
    formData.append(`isSubscription`, isSubscription);
    formData.append(`Frecuency`, Frecuency);
    formData.append(`GatewayId`, GatewayId);
    formData.append(`GatewayPaymentsId`, GatewayPaymentsId);

    // if (meetingFiel!== '') {
    //   formData.append(`meetingFiel`, meetingFiel);
    // }

    TaxFilesInfo.forEach((taxFile, index) => {
      if(taxFile.DocumentFile !== '' && taxFile.DocumentTypeId !== 0){
        formData.append(`TaxDocuments[${index}].DocumentTypeId`, taxFile.DocumentTypeId)
        formData.append(`TaxDocuments[${index}].DocumentFile`, taxFile.DocumentFile)
      }
    });

    //Aqui empieza el guardado
    if(crmSaleSelected){
      formData.append('id', crmSaleSelected.id);
      dispatch(crmSaleStartUpdate(formData, history, CRM_SALES));
    }else{
      if(isFormValid()){
        Object.keys(customerData).forEach(function(key) {
          if (key === 'password') {
            formData.append(`User.${key}`,profileData.rfc);
          }else{
            formData.append(`User.${key}`, customerData[key]);
          }
        });
       
        Object.keys(profileData).forEach(function(key) {
          if (key === 'regimes') {
            profileData[key].forEach((regime, index) => {
              const regimeSelected = regimes.filter(e => (e.id === regime))[0];
              formData.append(`Profile.Regimes[${index}].RegimeId`, regime)
              formData.append(`Profile.Regimes[${index}].accounting`, regimeSelected.accounting)
              formData.append(`Profile.Regimes[${index}].invoicing`, regimeSelected.invoicing)
            });
          }else{
            if (key === 'rfc') {
              formData.append(`profile.${key}`, profileData[key].toUpperCase());
            }else{
              formData.append(`profile.${key}`, profileData[key]);
            }
          }
        });
    
        Object.keys(address).forEach(function(key) {
          formData.append(`profile.Address.${key}`, address[key]);
        });

        if (InvoiceTaxPayer){
          Object.keys(taxPayer).forEach(function(key) {
            formData.append(`invoiceData.DataToInvoice.TaxPayer.${key}`, taxPayer[key]);
          });
        } 
        
        salesData.forEach((sale, index) => {
          formData.append(`Products[${index}].productId`, sale.productId)
          formData.append(`Products[${index}].total`, sale.price)
        });

        dispatch(startRegisterCRMSale(formData, history, CRM_SALES));
      }
    }
  }

  const isFormValid = () => {
    setMsgErrors({});

    let stringToValidate = {
      email: customerData.email,
      emailNotification: customerData.emailNotification,
      lastName: customerData.lastName,
      motherLastName: customerData.motherLastName,
      name: customerData.name,
      // password: customerData.password,
      endServiceAt: endServiceAt,
      startServiceAt: startServiceAt,
      bussinessName: profileData.bussinessName,
      postalCode:address.postalCode,
      startSatAt: profileData.startSatAt
    }

    // if(!sesionSat && accounting){
    //   stringToValidate['passwordSAT'] = profileData.passwordSAT;
    // }

    const numberToValidate = {total: total,professionId: profileData.professionId}

    salesData.forEach((saleData, index) => {
      numberToValidate[`productId${index}`] = saleData.productId
      numberToValidate[`price${index}`] = saleData.price
    })

    const arraysToValidate = {regimes: profileData.regimes}
        
    const validateString = validateStringPresence(stringToValidate, setMsgErrors);
    const validateNumber = validateNumberPresence(numberToValidate, setMsgErrors);
    const validateRfc = validateRfcValid(profileData.rfc, setMsgErrors);
    const validatePhone = validatePhoneValid(customerData.phoneMobile, setMsgErrors);
    const validateAarray = validateArrayPresence(arraysToValidate, setMsgErrors);
    
    if(Object.keys(referenceDataRedPay).length !== 0){
      stringToValidate['paymentAt'] =voucher.paymentAt;
      const {voucherFiles} = voucher;
      const dataFileToValidate = { voucherFiles };
      const validateFiles = validateFilePresence(dataFileToValidate, setMsgErrors );
      if( validateString && validateNumber && validateRfc && validateAarray && validateFiles && validatePhone){
        return true;
      }
    }else{
      if( validateString && validateNumber && validateRfc && validateAarray && validatePhone){
        return true;
      }
    }

    // if(!sesionSat && accounting){
    //   swal('Contraseña SAT', 'La contraseña del sat es incorrecta, favor de revisar', 'error');
    //   return false;
    // }
   

    swal('Error al guardar', 'Se detectaron errores, favor de revisar', 'error');
    return false;
  }

  const handleServiceTypeChange = ( e ) => {
    const data = e.target.name;
    if(data === 'accounting'){
      if(invoice) setInvoice(!invoice)
      setAccounting(!accounting)
    }else if (data === 'invoice'){
      if(accounting) setAccounting(!accounting)
      setInvoice(!invoice)
    }
  }
  useEffect(() => {
    if(msgErrors){
        if(msgErrors.name || msgErrors.lastName || msgErrors.motherLastName || msgErrors.phoneMobile || msgErrors.emailNotification || msgErrors.email || msgErrors.password){
         setErrorCustomer(true)
        }else{
         setErrorCustomer(false)
        }
        if( msgErrors.productId0 || msgErrors.price0 || msgErrors.startServiceAt || msgErrors.endServiceAt || msgErrors.total ){
          setErrorSale(true)
        }else{
          setErrorSale(false)
        }
        if(msgErrors.bussinessName || msgErrors.rfc || msgErrors.regimes || msgErrors.startSatAt || msgErrors.passwordSAT || msgErrors.professionId){
          setErrorTaxpayer(true)
        }else{
          setErrorTaxpayer(false)
        }
        if(msgErrors.postalCode){
          setErrorAddress(true)
        }else{
          setErrorAddress(false)
        }
        if(msgErrors.voucherFiles || msgErrors.paymentAt){
          setErrorVoucher(true)
        }else{
          setErrorVoucher(false)
        }
      
    }
    }, [msgErrors])
  

  useEffect(() =>{
    if(Object.keys(referenceDataRedPay).length !== 0 && referenceDataRedPayFinish){
      if(referenceDataRedPay.isAccounting){
        setInvoice(false)
         setAccounting(true)
      }else{
        setAccounting(false)
        setInvoice(true)
      }
        dispatch(crmReferenceRedPayFinish())
    }
  },[accounting,invoice,referenceDataRedPay]);


  return (
    <div className="container">
      <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4">
        <div className="card-header">
          <div className="row">
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <h5>Datos de la venta</h5>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
              <Link to={CRM_SALES} className="btn btn-danger btn-sm float-right">
                <i className="fas fa-arrow-left"></i> Regresar
              </Link>
            </div>
          </div>
          
        </div>
      </div>

      <form className="mt-4" onSubmit={handleSubmit}>
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-3">
          <nav className="mt-4 mb-4">
            <div className="nav  nav-pills" id="nav-tab" role="tablist">
              <a className="nav-item nav-link show active border m-1" id="nav-customer-tab" data-toggle="tab" href="#nav-customer" role="tab" aria-controls="nav-customer" aria-selected="true">
                {errorCustomer === true ? ( 
                  <strong className='text-danger'>Datos del cliente</strong>
                ):(
                  <strong > Datos del cliente </strong>
                )}
              </a>
              <a className={`nav-item nav-link border m-1 ${errorSale && "text-danger"}`}  id="nav-sale-tab" data-toggle="tab" href="#nav-sale" role="tab" aria-controls="nav-sale" aria-selected="true"> 
                  <strong >Datos de la venta</strong>
              </a>
              <a className="nav-item nav-link border m-1 boder" id="nav-invoice-tab" data-toggle="tab" href="#nav-invoice" role="tab" aria-controls="nav-invoice" aria-selected="true">
              Datos de la factura
              </a>
              <a className={`nav-item nav-link border m-1 ${errorVoucher && "text-danger" }`} id="nav-payment-tab" data-toggle="tab" href="#nav-payment" role="tab" aria-controls="nav-payment" aria-selected="true">
              Comprobante de pago
              </a>
              <a className={`nav-item nav-link border m-1 ${errorTaxpayer && "text-danger" }`} id="nav-taxpayer-tab" data-toggle="tab" href="#nav-taxpayer" role="tab" aria-controls="nav-taxpayer" aria-selected="true">
              Datos del contribuyente
              </a>
              <a className={`nav-item nav-link border m-1 ${errorAddress && "text-danger" }`} id="nav-fiscal-tab" data-toggle="tab" href="#nav-fiscal" role="tab" aria-controls="nav-fiscal" aria-selected="true">
              Domicilio fiscal
              </a>
              <a className="nav-item nav-link border m-1" id="nav-csd-tab" data-toggle="tab" href="#nav-csd" role="tab" aria-controls="nav-csd" aria-selected="true">
              CSD Fiel
              </a>
              <a className="nav-item nav-link border m-1" id="nav-filerequired-tab" data-toggle="tab" href="#nav-filerequired" role="tab" aria-controls="nav-filerequired" aria-selected="true">
              Archivos requeridos
              </a>
              <a className="nav-item nav-link border m-1" id="nav-comments-tab" data-toggle="tab" href="#nav-comments" role="tab" aria-controls="nav-comments" aria-selected="true">
              Comentarios extras
              </a>
            </div>
          </nav>
          <div className="tab-content" id="nav-tabContent">
              <div className="tab-pane fade show active " id="nav-customer" role="tabpanel" aria-labelledby="nav-customer-tab" aria-selected="true">
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                        <h5>Datos del cliente</h5>
                        <hr />
                    </div>
                    <ModuleUserScreen
                      msgErrors={msgErrors}
                      saleFormValues={formValues}
                      saleSetFormValues={setFormValues}
                    />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-sale" role="tabpanel" aria-labelledby="nav-sale-tab">
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-1">
                  <h5>Datos de la venta</h5>
                  <hr />
                </div >
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                <span>Referencia de pago</span>
                </div>
                  <ModulePaymentReference 
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                  />
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                  <span>Tipo de servicio</span>
                    <div className="row">
                        <div className="col-md-6 mb-3">
                          <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox"
                              name="accounting" 
                              id={accounting}
                            onChange={ handleServiceTypeChange }
                            value={ accounting }
                            checked={accounting}
                            disabled={ Object.keys(referenceDataRedPay).length !== 0  }
                            />
                            <label className="form-check-label">
                              Contabilidad
                            </label>
                          </div>
                        </div>
                        <div className="col-md-6 mb-3">
                          <div className="form-check">
                            <input 
                              className="form-check-input" 
                              type="checkbox"
                              name="invoice" 
                              id={invoice}
                              onChange={ handleServiceTypeChange }
                              value={ invoice }
                              checked={invoice}
                             disabled={Object.keys(referenceDataRedPay).length !== 0}
                            />
                            <label className="form-check-label">
                              Facturación
                            </label>
                          </div>
                        </div>
                        <ModuleSaleScreen
                      isInvoicing={invoice}
                      isAccounting={accounting}
                      msgErrors={msgErrors}
                      saleFormValues={formValues}
                      saleSetFormValues={setFormValues}
                      saleHandleInputChange={handleInputChange}
                    /> 
                    </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-invoice" role="tabpanel" aria-labelledby="nav-invoice-tab">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <h5>Datos de la Factura</h5>
                    <hr />
                  </div>
                  <ModuleInvoiceScreen
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                    handleCheckboxTrueFalseChange={handleCheckboxTrueFalseChange}
                  />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <h5>Comprobante de pago</h5>
                    <hr />
                  </div>
                  <ModuleVoucherScreen 
                    msgErrors={msgErrors}
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                  />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-taxpayer" role="tabpanel" aria-labelledby="nav-taxpayer-tab">
                <div className="row">
                  <ModuleTaxpayerScreen
                    isInvoicing={invoice}
                    isAccounting={accounting}
                    msgErrors={msgErrors}
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                  />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-fiscal" role="tabpanel" aria-labelledby="nav-fiscal-tab">
                <div className="row">
                  <ModuleAddressScreen
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                    isDiasabled={!!crmSaleSelected}
                    msgErrors={msgErrors}
                  />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-csd" role="tabpanel" aria-labelledby="nav-csd-tab">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
                    <div className="row">
                      <ModuleCSDScreen
                        saleFormValues={formValues}
                        saleSetFormValues={setFormValues}
                      />

                      <ModuleFielScreen
                        saleFormValues={formValues}
                        salaHandleInputChange={handleInputChange}
                        saleSetFormValues={setFormValues}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="nav-filerequired" role="tabpanel" aria-labelledby="nav-filerequired-tab">
                <div className="row">
                  <ModuleFilesScreen
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                  />
                </div>
              </div>
              <div className="tab-pane fade" id="nav-comments" role="tabpanel" aria-labelledby="nav-comments-tab">
                <div className="row">
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                    <h5>Comentarios extras</h5>
                    <hr />
                  </div>
                  <ModuleCommentScreen
                    saleFormValues={formValues}
                    saleSetFormValues={setFormValues}
                  />
                  <hr/>
                  <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 text-right">
                    <button className="btn btn-primary">
                      Guardar
                    </button>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </form>
    </div>
  )
}
