export const types = {
  authCheckingFinish: '[Auth] Finish Checking Login State',
  authStartLogin: '[Auth] Start Login',
  authLogin: '[Auth] Login',
  authStartRegister: '[Auth] Start Register',
  authValidatePartner: '[AuthPartner] Validate Partner',
  authRegister: '[Auth] Register',
  authStartTokenRenew: '[Auth] Start Token Renew',
  authLogout: '[Auth] Logout',
  accounterStartLoading: '[Accounters] Start Loading',

  certificatesStartLoading: '[Certificate] Start Loading',
  certificatesStartRegister: '[Certificate] Start Register',

  cfdiRelatedsTypeAddToList: '[Cfdi Related] Add To List',
  cfdiRelatedsChangeTypeSelected: '[Cfdi Related] Change Type Selected',
  cfdiRelatedsTypeDeleteToList: '[cfdi Related] Delete To List',

  cfdiUseBillingStartLoading: '[CFDI Use Billing] Start Loading',

  cfdiUseStartLoading: '[CFDI Use] Start Loading',
  coinsStartLoading: '[Coin] start Loading',
  crmRenovationActive: '[CRM Renovation] Active',

  crmSaleRenovationsStartLoading: '[CRM Renovations] Start Loading',
  crmSaleRenovationVouchersStartLoading: '[CRM Renovations Vouchers] Start Loading',

  crmSaleVoucherStartLoading: '[CRM Sale Voucher] Start Loading',

  crmSalesStartLoading: '[CRM Sales] Start Loading',
  crmSaleOptainSelected: '[CRM Sales] Optain Selected',
  crmSaleClearSelected: '[CRM Sales] Clear Selected',
  crmSaleActive: '[CRM Sales] Active',

  complementStartChangeAutorization: '[Complement] Start Change Autorization',
  complementStartLoad: '[Complements] Start Loading',
  complementStartShow: '[Complements] Start Show',
  complementAddExpense: '[Complement Expense] Add expense cmplement',
  complementAddIncome: '[Complement Income] Add income cmplement',

  csvInstructions: '[Instructions] start loading instructions',

  crmPreSalesStartLoading: '[CRM Pre Sale] Start Loading',
  crmPreSalesRegister: '[CRM Pre Sale] Start Register',
  crmSalePreSelected: '[CRM Pre Sale] Pre Sale Selected',
  crmSalePreValidateSat: '[CRM Pre Sale] Pre Validate Sat',
  crmPreSaleLink: '[CRM Pre Sale] Pre Link Payment',
  crmPreSaleInfoTax: '[CRM Pre Sale] Pre Info Tax',
  crmPreSaleFilesCSD: '[CRM Pre Sale] Upload files csd',
  crmPreSaleFiles: '[CRM Pre Sale] Upload files',
  customerBillingLoading: '[Customers Billing] Loading Customers Billing',
  crmReferenceCodePromotion: '[CRM Promotions] Reference Code',
  referenceRedPay: '[CRM Sale] Search Reference RedPay',
  referenceRedPayFinish: '[CRM Sale] Search Reference RedPay Success',
  referenceRedPayClear: '[CRM Reset Reference] Reset Reference RedPay',
  registerActivateModule: '[Register Billing] Register Module Billing',
  registerBill: '[Register Bill] Register Activate Bill',
  // activateAccount: '[CRM Pre Sale] Start Loading',

  serviceDateUpdate: '[ Sales ] Sale Date Change',

  documentTaxTaxpayerTypesStartLoading: '[Document Tax Taxpayer Type] Start Loading',

  downloadXMLRequestStartLoading: '[Download XML Request] Start Loading',

  expenseStartChangeAutorization: '[Expense] Start Change Autorization',
  expenseStartRegister: '[Expense] Start Register',
  expenseStartUpdate: '[Expense] Start Update',
  expenseStartLoading: '[Expense] Start Loading',
  expenseFinishLoading: '[Expense] Finish Loading',
  expenseFinishStatus: '[Expense] Finish Status',

  expenseTypesStartLoading: '[Expense Type] Start Loading',
  expenseClassificationsStartLoading: '[ExpenseClassifications] Start Loading ExpenseClassifications',
  expenseClassificationsSelect: '[Expense Select] Expense Select',
  expenseClassificationUpdate: '[Expense Select Update] Expense Select',
  expenseClassificationAdd: '[Expense Add] Expense Add',

  incomeStartChangeAutorization: '[Income] Start Change Autorization',
  incomeStartLoading: '[Income] Start Loading',
  incomeStartRegister: '[Income] Start Register',
  incomeStartUpdate: '[Income] Start Update',
  incomeFinishLoading: '[Income] Finish Loading',
  incomeFinishStatus: '[Income] Finish Status',

  invoicesActive: '[Record Invoices Billing] start Loading',

  keyUnitsStartLoading: '[key Unit] start Loading',

  mailboxStartLoading: '[Mail] Start Loading',
  mailboxStartUpdate: '[Mail] Start Update',

  packageReportsAssistedStartLoading: '[Package Reports Assisteds] Start Loading',
  paymentGatewayStartLoading: '[Payment Gateway] start Loading',
  paymentFrecuencieCfdisStartLoading: '[Payment Frecuencie] start Loading',

  paymentMethodCfdisStartLoading: '[Payment Method] start Loading',
  paymentStatusStartLoading: '[Payment Status] Start Loading',
  paymentMethodsBillingStartLoading: '[Payment Method Billing] Start Loading',


  periodStartDownloadXMLSAT: '[Period] Start Download XML SAT',
  finishPackage: '[status Package] finish package',
  preAuthorizationReportStartRegister: '[Pre Authorization Report] Start Register',
  preAuthorizationReportStartMonthPrevRegister: '[Pre Authorization Report] Month Previus Start Register',
  preAuthorizationReportUpdateRegister: '[Pre Authorization Report] Update Register',
  preAuthorizationReportGetByProfile: '[Pre Authorization Report] Get By Profile',
  preAuthorizationReportCheckToken: '[Pre Authorization Report] Check Token',
  preAuthorizationReportUpdateToken: '[Pre Authorization Report] Update Token',
  authorizationReportStatus: '[Authorization Report] Authorization Report',
  statusReportUdapte: '[Status Report] Update Status Report',
  preAuthorizationTaxDocumentsStartLoading: '[Pre Authorization Tax Documents] Start Loading',
  preAuthorizationReportDepreciation: '[Pre Authorization Report] Add Depreciation]',
  productToSaleStartLoading: '[Product To Sale] Start Loading',
  productsToPaymentLinkStartLoading: '[Products To Sale Payment] Start Loading',
  productsToPaymentLink: '[Products To Sale Payment] Link product',
  productBillingLoading: '[Products To Sale Billing] Start Loading',

  professionsStartLoading: '[Profession] Start Loading',

  receptorAccountBanksStartLoading: '[Receptor Account Banks] Start loading',

  regimenesStartLoading: '[regimenes] Start Loading',

  regimenesBilling: '[regimenesBilling] Billing Start Loading]',

  receiverData: '[receiverData] Start Loading',

  retentionsStartLoading: '[Retentions] Start Loading',
  retentionsFinishLoading: '[Retentions] Finish Loading',

  roleTypesStartLoading: '[Role Type] Start Loading',

  serviceTypeRetentionsStartLoading: '[Service Type Retentions] Start Loading',

  socialMediaTypeActives: '[SMT] Social Media Types Actives',
  socialMediaTypeLoadActives: '[SMT] Social Media Type Load Actives',

  taxpayerAddressesStartRegister: '[Taxpayer Addresses] Start Register',
  taxpayerAddressesStartUpdate: '[Taxpayer Addresses] Start Update',
  taxpayerReport: '[Taxpayer Report] Start Taxpayer Report Status',
  taxpayerAssisteds: '[Taxpayer Assisteds] Taxpayer Assisteds',
  taxpayerAssistedsStartLoading: '[Taxpayer Assisteds] Start Loading',
  updateDashInfo: '[Update Dashboard Taxpayer] Update Dashboard Taxpayer',
  updateDashInfoPush: '[Update Push Dashboard Taxpayer] Update Dashboard Taxpayer',
  taxpayerAssistedChangeAccountant: '[Taxpayer Assisteds] Change Accountant',
  taxpayerAssistedChangePasswordSat: '[Taxpayer Assisteds] Change PasswordSat',
  taxpayerAssistedsFinishStartLoading: '[Taxpayer Assisteds Finish] Start Loading',
  taxpayerAssistedsFinishSearch: '[Taxpayer Assisteds Finish Search] Assisteds Finish Search',
  updatePackageToPresent: '[Update package to present] Update package to present',

  taxpayerChangeActivation: '[Taxpayer] Change Activation',
  taxpayerSelectedLoaded: '[Taxpayer] Selected Loaded',
  taxpayerStartLoading: '[Taxpayer] Start Loading',
  taxpayerStartRegister: '[Taxpayer] Start Register',
  taxpayerStartUpdate: '[Taxpayer] Start Update',
  taxpayerStartValidationSat: '[Taxpayer] Start validation password sat',
  sessionSatTaxpayerLoading: '[Taxpayer] Finish validation password sat',
  taxFilesStartLoading: '[Taxfiles] Start Loading',
  taxDocumentBilling: '[Taxfiles Billing] Start Loading',
  taxpayerActivity: '[taxpayerActivity] Activity taxpayer',

  thirdPartyStartRegister: '[Third Party] Start Register',
  thirdPartyStartLoading: '[Third Party] Start Loading',

  thirdPartyTypeStartLoadind: '[Third Party Type] Start Loading',

  wayOfPaymentCfdisStartLoading: '[Way Of Payment CFDI] start Loading',
  wayOfPaymentBillingStartLoading: '[Way Of Payment Billing] start Loading',

  zipCodeSearchLoading: '[Zip Code] Search Loading',
  zipCodeClearLoading: '[Zip Code] Clear Loading',

  logout: '[Auth] Logout',
  register: '[Auth] Register',

  uiSetError: '[UI] Set Error',
  uiRemoveError: '[UI] Remove Error',

  uiStartLoading: '[UI] Start loading',
  uiFinishLoading: '[UI] Finish loading',

  unitTypesStartLoading: '[Unit Type] start Loading',

  userValidateEmail: '[User] Validate email',
  userGetByMail: '[User] Get By Mail',
  userStartLoading: '[User] Get all users',
  userType: '[User] Get all user type',
  userAddNew: '[User] Add New',
  userUpdated: '[User] Updated',
  userDeleted: '[User] Deleted',
  userSelectedLoaded: '[User] Selected Loaded',

  userBillingLoaded: '[User Billing] User Billing Loaded',

  identifierSetSelected: '[identifier] Set Selected',
  identifierClearSelected: '[identifier] Clear Selected',
  identifierAddNew: '[identifier] Add New',
  identifierUpdated: '[identifier] Updated',
  identifierDeleted: '[identifier] Deleted',

  invoiceTypeSearchId: '[Invoice Type] Search By Id',

  rolesStartLoading: '[Roles Type] start Loading',

  serieFolioAddNew: '[SerieFolio] Add New',
  serieFolioUpdated: '[SerieFolio] Updated',
  serieFolioDeleted: '[SerieFolio] Deleted',

  lineItemAddToList: '[Line Item] Add To List',
  lineItemDeletedToList: '[Line Item] Deleted To List',
  lineItemAddNew: '[Line Item] Add New',

  occupationStartLoading: '[Occupation] Start Loading',

  serviceTypeStartLoading: '[Service Type] Start Loading',
  getRequestByPeriodStartLoading: '[Period] Start loadingModal',
  getDownloadSatStartLoading: '[Period]',
  getSessionSat: '[Check] Session Sat status',
  getSessionRetentionSat: '[Check] Session retention Sat status',
  zoneTypeStartLoading: '[Zone Type] Start Loading',

  localTaxesAddToList: '[Local Taxe] Add To List',
  localTaxesUpdateToList: '[Local Taxe] Update To List',
  localTaxesDeleteToList: '[Local Taxe] Delete To List',

  metricApp: '[Metric User App] Get Metric user App',

  activitiesStartLoading: '[Activities] Start loading activities',
  //types para configuracion de reglas
  activitiesByRegimen: '[Activities] Start Loading',
  regimesConfig: '[regimes] Start Loading',
  clavProdServConfig: '[classify] Start Loading',
  clavProdServStartLoading: '[clavProdServ] Start Loading',
  clavProdServNotValidated: '[classifyNotValidated] Start Loading',
  opinionComplement: '[opinion] Start Loading',
}
