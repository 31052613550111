import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import * as ROUTES from '../../../constants/Routes';
import { DETAILS_EMPLOYEE } from '../../../constants/Routes';
import { Link } from 'react-router-dom';
import { LogoContabilizate } from '../../crm/sales/PreSale/LogoContabilizate';
import { useSelector } from 'react-redux';
import swal from 'sweetalert';

const ReferencesCode = () => {

    const { user } = useSelector(state => state.auth);

    const handleCopyCode = ( event ) => {
        event.preventDefault()
        navigator.clipboard.writeText(user?.referenceCode)
        swal('Exito', 'Código copiado en el portapapeles', 'success');
    }

    console.log('USER', user )
    return (
        <Fragment>
        <div className="container">
           <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2">
               <div className="card col-sm-8 shadow mb-4">
                    <div className="card-body">
                        <LogoContabilizate />

                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-center">
                                <h3> Código de Referencia </h3>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3 text-center">
                                <h1 className='text-primary'> { user?.referenceCode }</h1>
                            </div>
                        </div>
                        <div className='row'>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-4 text-center">
                                <button 
                                    className='btn btn-success'
                                    onClick={ handleCopyCode }
                                > 
                                    Copiar Código 
                                </button>
                            </div>
                        </div>
                    </div>
               </div>
           </div>
       </div>
   </Fragment>
    )
}

export default ReferencesCode;

