import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';

import * as ROUTES from '../components/constants/Routes';

import Login from '../components/pages/principal/LoginScreen';
import Register from '../components/pages/principal/RegisterScreen';
import { RecuperatePassword } from '../components/pages/principal/auth/RecuperatePassword';
import { ChangePassword } from '../components/pages/principal/auth/ChangePassword';

import TaxpayerScreen from '../components/pages/customer/taxpayers/TaxpayerScreen';
import TaxpayerDetailsScreen from '../components/pages/customer/taxpayers/TaxpayerDetailsScreen';

import InvoicesIndex from '../components/pages/customer/invoicing/Index';
import BillingIndex from '../components/pages/customer/billing/Index';

import { InvoiceFormScreen } from '../components/pages/customer/invoicing/InvoicesTypeIncomes/InvoiceFormScreen';
import { PaidComplementScreen } from '../components/pages/customer/invoicing/InvoicesTypeComplements/PaidComplementScreen';

import PayrollIndex from '../components/pages/customer/payroll/Index';
import { PayrollFormScreen } from '../components/pages/customer/payroll/PayrollFormScreen';
import { EmployeeFormScreen } from '../components/pages/customer/payroll/EmployeeFormScreen';
import { PayrollDetailsScreen } from '../components/pages/customer/payroll/DetailsPayroll/PayrollDetailsScreen';
import { EmployeeDetailsScreen } from '../components/pages/customer/payroll/DetailsEmployee/EmployeeDetailsScreen';

import PromotionsIndex from '../components/pages/customer/promotions/Index';

import AccountingIndex from '../components/pages/customer/accounting/Index';
import { DetailsSummary } from '../components/pages/customer/accounting/annualSummary/detailedSummary/DetailsSummary';
import { ConfiguracionCatalogos2 } from '../components/pages/administrators/opinion';

import Navbar from '../components/layout/Navbar';
import { NavbarCustomer } from '../components/layout/NavbarCustomer';
// import { Footer } from '../components/layout/Footer';

import { startChecking } from '../actions/auth';
import { PublicRoute } from './PublicRoute';
import { PrivateRoute } from './PrivateRoute';
import { DetailsPreReportScreen } from '../components/pages/customer/accounting/annualSummary/detailedSummary/DetailsPreReportScreen';
import { PrivateAdminRoute } from './PrivateAdminRoute';
import { AdminIndexScreen } from '../components/pages/administrators/AdminIndexScreen';
import { AdminSalesIndexScreen } from '../components/pages/administrators/sales/AdminSalesIndexScreen';
import { UserAdminsIndexScreen } from '../components/pages/administrators/users/UserAdminsIndexScreen';
import { AdminAccountingSaleFormScreen } from '../components/pages/administrators/sales/accounting/AdminAccountingSaleFormScreen';
import { CrmSalesIndexScreen } from '../components/pages/crm/sales/CrmSalesIndexScreen'; import { CrmSalesFormScreen } from '../components/pages/crm/sales/CrmSalesFormScreen';
import { CrmAccountingRenovationFormScreen } from '../components/pages/crm/renovations/accountingRenovations/CrmAccountingRenovationFormScreen';
import { CrmRenovationsIndexScreen } from '../components/pages/crm/renovations/CrmRenovationsIndexScreen';
import { CrmInvocingRenovationFormScreen } from '../components/pages/crm/renovations/invoicingRenovations/CrmInvocingRenovationFormScreen';
import { AdminInvocingSaleFormScreen } from '../components/pages/administrators/sales/invoicing/AdminInvocingSaleFormScreen';
import { AdminReportsIndexScreen } from '../components/pages/administrators/reports/AdminReportsIndexScreen';
import { AdminNewSaleFormScreen } from '../components/pages/administrators/sales/newUsers/AdminNewSaleFormScreen';
import Products from '../components/pages/crm/sales/Products';
import { AddProducts } from '../components/pages/crm/sales/AddProducts';
import { PreSaleForm } from '../components/pages/crm/sales/PreSale/PreSaleForm';
import { ValidateSat } from '../components/pages/crm/sales/PreSale/ValidateSat';
import { UploadFiles } from '../components/pages/crm/sales/PreSale/UploadFiles';
import { IndexMetric } from '../components/pages/customer/metric/IndexMetric';
import IndexCatalogs from '../components/pages/administrators/catalogs/expenses/IndexExpenseClassification';
import { FormExpenseClassification } from '../components/pages/administrators/catalogs/expenses/FormExpenseClassification';
import { InformationTaxpayer } from '../components/pages/crm/sales/PreSale/InformationTaxpayer';
import { Payment } from '../components/pages/crm/sales/PreSale/Payment';
import { CrmPreSalesIndex } from '../components/pages/crm/CrmPreSalesIndex';
import { Details } from '../components/pages/crm/sales/PreSale/Details';
import ConfiguracionCatalogos from '../components/pages/administrators/configuration/catalogs/ConfiguracionCatalogos/ConfiguracionCatalogos';
import ConfiguracionActividades from '../components/pages/administrators/configuration/catalogs/ConfiguracionActividades/ConfiguracionActividades';
import ConfiguracionClaveProdServ from '../components/pages/administrators/configuration/catalogs/ConfiguracionClaveProdServ/ConfiguracionClaveProdServ';

export const AppRouter = () => {

  const dispatch = useDispatch();

  const { checking, user } = useSelector(state => state.auth)

  useEffect(() => {
    dispatch(startChecking());
  }, [dispatch]);

  if (checking) {
    return '';
  }

  return (
    <div>
      <Router>
        {!!user ? (<NavbarCustomer />) : (<Navbar />)}
        <div>
          <Switch>
            <PublicRoute exact path={ROUTES.HOME} component={Login} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PublicRoute exact path={ROUTES.LOGIN} component={Login} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <Route exact path={ROUTES.REGISTER(':code')} component={Register} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <Route exact path={ROUTES.REGISTER2} component={Register} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PublicRoute exact path={ROUTES.RECUPERATE_PASSWORD} component={RecuperatePassword} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PublicRoute exact path={ROUTES.PRODUCTS} component={Products} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <Route exact path={ROUTES.AUTORIZATION_PREREPORT(':token')} component={DetailsPreReportScreen} />
            <Route exact path={ROUTES.CHANGE_PASSWORD(':token')} component={ChangePassword} />

            <PrivateRoute exact path={ROUTES.TAXPAYERS} component={TaxpayerScreen} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.DETAILS_TAXPAYER(':taxpayerId')} component={TaxpayerDetailsScreen} isAuthenticated={!!user} />

            <PrivateRoute exact path={ROUTES.INVOICES} component={InvoicesIndex} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.NEW_INVOICES(':taxpayerId')} component={InvoiceFormScreen} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.NEW_PAIDCOMPLEMENT(':taxpayerId')} component={PaidComplementScreen} isAuthenticated={!!user} />

            <PrivateRoute exact path={ROUTES.PAYROLL} component={PayrollIndex} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.BILLING} component={BillingIndex} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.NEW_PAYROLL} component={PayrollFormScreen} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.DETAILS_PAYROLL(':payrollId')} component={PayrollDetailsScreen} isAuthenticated={!!user} />

            <PrivateRoute exact path={ROUTES.REFERENCE_CODE} component={PromotionsIndex} isAuthenticated={!!user} />

            <PrivateRoute exact path={ROUTES.NEW_EMPLOYEE} component={EmployeeFormScreen} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.DETAILS_EMPLOYEE(':employeeId')} component={EmployeeDetailsScreen} isAuthenticated={!!user} />

            <PrivateRoute exact path={ROUTES.ACCOUNTING(':taxpayerId', ':regimeSatCode', ':year')} component={AccountingIndex} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.DETAILS_SUMMARY(':taxpayerId', ':regimeSatCode', ':year', ':month', ':isAdmin')} component={DetailsSummary} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.OPINION_CONSTANCE(':rfc')} component={ConfiguracionCatalogos2} isAuthenticated={!!user} />

            <PrivateAdminRoute exact path={ROUTES.ADMIN} component={AdminIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_SALES} component={AdminSalesIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exect path={ROUTES.ADMIN_SALE_UPDATE(':saleId')} component={AdminNewSaleFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_ACCOUNTING_SALE_FORM} component={AdminAccountingSaleFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_ACCOUNTING_SALE_UPDATE(':saleId')} component={AdminAccountingSaleFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_INVOICING_SALE_FORM} component={AdminInvocingSaleFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_INVOICING_SALE_UPDATE(':saleId')} component={AdminInvocingSaleFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ADMIN_ADMINISTRATORS} component={UserAdminsIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <PrivateAdminRoute exact path={ROUTES.CRM_SALES} component={CrmPreSalesIndex} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            {/* <PrivateAdminRoute exact path={ROUTES.CRM_SALES_FORM} component={CrmSalesFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null}/> */}
            <PrivateAdminRoute exact path={ROUTES.CRM_SALES_PRODUCTS} component={AddProducts} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CRM_SALES_UPDATE_FORM(':saleId')} component={CrmSalesFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CRM_RENOVATIONS} component={CrmRenovationsIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CRM_ACCOUNTING_RENOVATIONS_NEW} component={CrmAccountingRenovationFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CRM_ACCOUNTING_RENOVATIONS_UPDATE(':saleId')} component={CrmAccountingRenovationFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <PrivateAdminRoute exact path={ROUTES.CRM_INVOICING_RENOVATIONS_NEW} component={CrmInvocingRenovationFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CRM_INVOICING_RENOVATIONS_UPDATE(':saleId')} component={CrmInvocingRenovationFormScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <PrivateAdminRoute exact path={ROUTES.ADMIN_REPORTS} component={AdminReportsIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.ACCOUNT_REPORTS} component={AdminReportsIndexScreen} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.CATALOG_EXPENSE_CLASSICATION} component={IndexCatalogs} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.FORM_EXPENSE_CLASSIFICATION()} component={FormExpenseClassification} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.FORM_EXPENSE_CLASSIFICATION_UPDATE(':expenseId')} component={FormExpenseClassification} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.VIEW_CONFIG_CATALOGS} component={ConfiguracionCatalogos} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.VIEW_CONFIG_NEWS} component={ConfiguracionCatalogos} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.VIEW_CONFIG_ACTIVITIES(':idRegimen')} component={ConfiguracionActividades} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />
            <PrivateAdminRoute exact path={ROUTES.VIEW_CONFIG_CLASSIFY(':idRegimen', ':idActivity')} component={ConfiguracionClaveProdServ} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <PrivateRoute exact path={ROUTES.TAXPAYERS} component={TaxpayerScreen} isAuthenticated={!!user} />
            <PrivateRoute exact path={ROUTES.FORM_PRESALE} component={PreSaleForm} isAuthenticated={!!user} />
            <Route exact path={ROUTES.FORM_PRESALE_PASSAT(':token')} component={ValidateSat} />
            <Route exact path={ROUTES.FORM_PRESALE_FILES(':token')} component={UploadFiles} />
            <Route exact path={ROUTES.FORM_PRESALE_TAXPAYER(':token')} component={InformationTaxpayer} />
            <Route exact path={ROUTES.FORM_PRESALE_PAYMENT(':token')} component={Payment} />
            <PrivateAdminRoute exact path={ROUTES.FORM_PRESALE_DETAILS(':token')} component={Details} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />



            <PrivateAdminRoute exact path={ROUTES.METRIC} component={IndexMetric} isAuthenticated={!!user} role={!!user ? user.descriptionRole : null} />

            <Redirect to={ROUTES.LOGIN} />
          </Switch>
        </div>
        {/* <Footer/> */}
      </Router>
    </div>
  )
}
