import React, { useEffect, Fragment, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { TextField } from '@material-ui/core';
import { useParams, useHistory } from 'react-router';
import { useForm } from '../../../../../hooks/useForm'
import { LogoContabilizate } from './LogoContabilizate';
import { AvisoPrivacidad } from './AvisoPrivacidad';
import { crmPreSaleStartSelected, startValidateSatPreSale } from '../../../../../actions/crm/preSales';

export const ValidateSat = () => {

    const { token } = useParams();
    let history = useHistory();

    const { crmSalePreSelectedInfo, captcha } = useSelector(state => state.crmPreSale);
    const dispatch = useDispatch();

    const [msgErrors, setMsgErrors] = useState({});
    const [disabledText, setDisabledText] = useState(false);

    const { formValues, handleInputChange, handleCheckboxTrueFalseChange, setFormValues } = useForm({
        passwordSat: '',
        rfc: '',
        imgcaptcha: '',
    }
    );

    const { rfc, passwordSat, imgcaptcha } = formValues;

    useEffect(() => {
        if (token && !disabledText) {
            dispatch(crmPreSaleStartSelected(token))
            dispatch(startValidateSatPreSale({ token: token, passwdSAT: "", captcha: "" }, null, 'Obteniendo captcha'))
        }
    }, [token])

    useEffect(() => {
        if (crmSalePreSelectedInfo) {
            if (crmSalePreSelectedInfo.stepsJson && crmSalePreSelectedInfo.stepsJson.stepCiec) {
                setDisabledText(true)
                setFormValues(prevState => {
                    return {
                        ...prevState,
                        passwordSat: crmSalePreSelectedInfo.stepsJson.stepCiec.passwdSAT
                    }
                })
            }
            setFormValues(prevState => {
                return {
                    ...prevState,
                    rfc: crmSalePreSelectedInfo.rfc
                }
            })
        }
    }, [crmSalePreSelectedInfo])

    const handleRegister = (e) => {
        e.preventDefault();
        if (isFormValid()) {
            dispatch(startValidateSatPreSale({ token: token, rfc: rfc, passwdSAT: passwordSat, captcha: imgcaptcha }, history))

        }
    }

    const isFormValid = () => {
        const errors = {};
        if (rfc.trim().length === 0) {
            errors['msgRfc'] = "Es requerido."
        }
        if (passwordSat.trim().length === 0) {
            errors['msgPasswordSat'] = 'Es requerido.';
        }
        // if (imgcaptcha.trim().length === 0) {
        //     errors['msgCaptcha'] = 'Es requerido.';
        // }

        setMsgErrors(errors);

        if (Object.keys(errors).length !== 0) {
            return false;
        }

        return true;
    }


    return (
        <Fragment>
            <div className="container">
                {/* <StepsPreSale/> */}
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-center my-2">
                    <div className="card col-sm-8 shadow mb-4">
                        <div className="card-body">
                            <LogoContabilizate name={<span>Contraseña Sat<span title="Clave De Identificación Electrónica Confidencial"> (CIEC)</span></span>} />
                            <form className="mt-4" onSubmit={handleRegister}>
                                <div className='row'>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                <TextField
                                                    label="Rfc"
                                                    variant="outlined"
                                                    size="small"
                                                    id="rfc"
                                                    name="rfc"
                                                    value={rfc}
                                                    onChange={handleInputChange}
                                                    autoComplete="off"
                                                    helperText={msgErrors.msgRfc}
                                                    fullWidth={true}
                                                    error={msgErrors.msgRfc !== undefined}
                                                    disabled
                                                />
                                            </div>

                                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                <TextField
                                                    label="Contraseña SAT"
                                                    variant="outlined"
                                                    size="small"
                                                    id="passwordSat"
                                                    name="passwordSat"
                                                    value={passwordSat}
                                                    onChange={handleInputChange}
                                                    type="password"
                                                    disabled={disabledText}
                                                    autoComplete="off"
                                                    fullWidth={true}
                                                    helperText={msgErrors.msgPasswordSat}
                                                    error={msgErrors.msgPasswordSat !== undefined}
                                                />
                                            </div>
                                            {/* {!disabledText &&
                                                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mb-3">
                                                    <TextField
                                                        label="Captcha "
                                                        variant="outlined"
                                                        disabled={disabledText}
                                                        size="small"
                                                        id="imgcaptcha"
                                                        name="imgcaptcha"
                                                        value={imgcaptcha}
                                                        onChange={handleInputChange}
                                                        autoComplete="off"
                                                        fullWidth={true}
                                                        helperText={msgErrors.msgCaptcha}
                                                        error={msgErrors.msgCaptcha !== undefined}
                                                    />
                                                </div>
                                            } */}
                                        </div>
                                        {!disabledText &&
                                            <>
                                                {/* <div className="text-center my-3">
                                                    {!captcha ? (
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                    ) : (
                                                        <img className="text-center" height="60" src={'data:image/jpeg;base64,' + captcha} />
                                                    )
                                                    }
                                                </div> */}


                                                <div className="row col-md-12 text-center">
                                                    <div className="col-12">
                                                        <button type="submit" className="btn btn-success">Guardar</button>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {disabledText &&
                                            <h3 className='text-info text-center'>Credenciales correctas</h3>
                                        }


                                    </div>
                                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6 mb-3">
                                        <div className="col-12 text-center">
                                            <img className="text-center" height="300" src='rfcPreSale.png' />
                                        </div>
                                    </div>

                                    <AvisoPrivacidad />

                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
