import React, { Fragment,useState,useEffect } from 'react';
import { crmPreSaleStartSelected } from '../../../../../actions/crm/preSales';
import { InformationTaxpayer } from './InformationTaxpayer';
import { Payment } from './Payment';
import { PreSaleForm } from './PreSaleForm';
import { UploadFiles } from './UploadFiles';
import { ValidateSat } from './ValidateSat';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';

export const Details = () => {

    const { token } = useParams();
    const dispatch = useDispatch();
  
    const [steps, setSteps] = useState([
        {id:1,description:'Registro',key:'register',show:true},
        {id:2,description:'Contraseña Sat',key:'passwordSat',show:false},
        {id:3,description:'Archivos',key:'filesCiec',show:false},
        {id:4,description:'Contribuyente',key:'informationTaxpayer',show:false},
        {id:5,description:'Pago',key:'payment',show:false},
    ]);

    useEffect(() => {
        if(token){
            dispatch(crmPreSaleStartSelected(token))
        }
    }, [token])

    return (

        <Fragment>
            <div className="pb-1 row justify-content-center">
                
                 <nav className="mt-4 mb-4">
                 <div className="nav  nav-pills" id="nav-tab" role="tablist">
                { 
                   
                    steps.map((step,index) => {
                      return(   
                        <a key={ step.id } value={ step.id } class={`m-2 ${step.show &&  "show active" } nav-item nav-link border `} id={`nav-${step.key}-tab`} data-toggle="tab" href={`#nav-${step.key}`} role="tab" aria-controls={`nav-${step.key}`} aria-selected="true" >
                            {step.description}
                        </a>
                      )
                    })
                   
                }
                </div>
                 </nav>
            </div>
            <div>
                 <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active " id="nav-register" role="tabpanel" aria-labelledby="nav-register-tab" aria-selected="true">  
                         <PreSaleForm/>
                    </div>
                    <div className="tab-pane fade " id="nav-passwordSat" role="tabpanel" aria-labelledby="nav-passwordSat-tab">
                        <ValidateSat/>
                    </div>
                    <div className="tab-pane fade " id="nav-filesCiec" role="tabpanel" aria-labelledby="nav-filesCiec-tab">
                        <UploadFiles load={false}/>
                    </div>
                    <div className="tab-pane fade " id="nav-informationTaxpayer" role="tabpanel" aria-labelledby="nav-informationTaxpayer-tab">
                        <InformationTaxpayer/>
                    </div>
                    <div className="tab-pane fade " id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab">
                        <Payment/>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
