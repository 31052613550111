import React, { useContext, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from '../../../../../../../hooks/useForm';
import { TextField, Checkbox, InputLabel, Select, FormControl, MenuItem, ListItemText } from '@material-ui/core';
import { regimesConfigLoading } from '../../../../../../../actions/catalogs/regimes';
import { editClaveProdServ } from '../../../../../../../actions/configuration/configurationActions';

const EditClavProdServModal = ({ idRegimen, idActivity, metodosPago, claveProdServ, id, idProffession, description, usocfdi, regimenRecep, formasPago, tipoGasto, limite, limiteFormaPago, check, valido }) => {
	const [searchTerm, setSearchTerm] = useState('');
	const [searchMode, setSearchMode] = useState('id'); // Puede ser 'id' o 'descripcion'
	const [resultadosBusqueda, setResultadosBusqueda] = useState([]);

	const { regimes } = useSelector(state => state.regimesConfig);
	const { paymentMethods } = useSelector(state => state.paymentMethod);
	const { wayOfPayments } = useSelector(state => state.wayOfPayment);
	const { clavProdServ } = useSelector(state => state.allClavProdServ);
	const { cfdiUses } = useSelector(state => state.cfdiUse);
	const { expenseTypes } = useSelector(state => state.expenseType);

	const [usoCFDI, setValues] = useState([]);
	const [payments, setPayments] = useState([]);

	const metodosDePago = [
		{ id: 1, label: 'Todos', value: 'PUE, PPD' },
		{ id: 2, label: 'PUE', value: 'PUE' },
		{ id: 3, label: 'PPD', value: 'PPD' },
	];

	const [editarCampos, setEdit] = useState(false);

	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(regimesConfigLoading());
	}, []);

	const handleSubmit = (e) => {
		e.preventDefault();
		console.log({ ...formValues })
		dispatch(editClaveProdServ({ ...formValues }));
	};

	const { formValues, setFormValues } = useForm({
		Catalog: "c_Classifications",
		Request: {
			Id: id,
			ProffessionId: idProffession,
			ActivityId: idActivity,
			ClaveProdServId: claveProdServ,
			ExpenseTypeId: tipoGasto,
			RegimenId: parseInt(regimenRecep),
			UseCfdi: usocfdi,
			WayOfPayment: formasPago,
			PaymentMethod: metodosPago.toString(),
			Limit: parseInt(limite),
			LimitPaymentForm: limiteFormaPago.toString(),
			Observation: description.toString(),
			Check: check,
			isValidated: valido
		}
	});

	const { ClaveProdServId, Observation, UseCfdi, RegimenId, WayOfPayment,
		PaymentMethod, ExpenseTypeId, Limit, LimitPaymentForm, Check, isValidated, Request } = formValues;

	const handleInputChange = ({ target }) => {
		const { name, value, type } = target;

		// Si el tipo es 'number', convierte el valor a número
		const newValue = type === 'number' ? parseInt(value, 10) : value;

		setFormValues(prevState => {
			return {
				...prevState,
				Request: {
					...prevState.Request,
					[name]: newValue
				}
			}
		})
	}

	const hangleCheck = ({ target }) => {
		const { name, checked } = target;

		setFormValues(prevState => {
			return {
				...prevState,
				Request: {
					...prevState.Request,
					[name]: checked
				}
			}
		});
	};

	const permitirEditar = () => {
		setEdit(!editarCampos);
	}

	const handleChangeSelectMultiple = ({ target }) => {
		const { value } = target;

		setValues(value);
		let usosCFDI = value.join(', ');

		setFormValues(prevState => ({
			...prevState,
			Request: {
				...prevState.Request,
				UseCfdi: usosCFDI
			}
		}));
	}

	const renderSelectMultiple = () => {
		let render = '';

		if (usoCFDI.length === 0) {
			return <em>Seleccionar Uso de CFDI</em>;
		}

		if (cfdiUses.length > 0) {
			const descriptions = usoCFDI.map(usos => {
				const usocfdi = cfdiUses.find(e => e.key === usos);
				return usocfdi ? usocfdi.description : ''; // Devolver la descripción o una cadena vacía si no se encuentra
			});

			render = descriptions.join(', '); // Unimos las descripciones con comas
		}

		return render;
	};

	const handleChangeSelectPayments = ({ target }) => {
		const { value } = target;

		setPayments(value);
		let payments = value.join(', ');

		setFormValues(prevState => ({
			...prevState,
			Request: {
				...prevState.Request,
				WayOfPayment: payments
			}
		}));
	};

	const renderSelectPayments = () => {
		let render = '';

		if (wayOfPayments.length === 0) {
			return <em>Seleccionar una Forma de Pago</em>;
		}

		if (wayOfPayments.length > 0) {
			const descriptions = payments.map(usos => {
				const payment = wayOfPayments.find(e => e.key === usos);
				return payment ? payment.description : ''; // Devolver la descripción o una cadena vacía si no se encuentra
			});

			render = descriptions.join(', '); // Unimos las descripciones con comas
		}

		return render;
	};

	return (
		<div style={{ width: "800px" }}>
			<div className='text-center'>
				<h5>Editar Clave de Producto o Servicio</h5>
			</div>
			<hr />
			<form onSubmit={handleSubmit}>
				<div className="row">
					<div className='d-flex justify-content-center align-items-end col-12 mb-3'>
						<label>
							Activar edición
						</label>
						<Checkbox
							name='Check'
							checked={editarCampos}
							value={editarCampos}
							onChange={permitirEditar}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
					</div>
					<div className='col-6 mb-3'>
						<TextField
							disabled={true}
							label="Clave de Producto:"
							variant='outlined'
							size="small"
							name='ClaveProdServId'
							autoComplete='off'
							value={Request.ClaveProdServId}
							onChange={handleInputChange}
							fullWidth
							inputProps={{ autoCapitalize: 'off' }}>
						</TextField>
					</div>
					<div className="col-6 mb-3">
						<TextField
							label="Observaciones:"
							variant='outlined'
							size="small"
							name='Observation'
							value={Request.Observation}
							onChange={handleInputChange}
							autoComplete='off'
							fullWidth
							inputProps={{ autoCapitalize: 'off' }}
						/>
					</div>
					<div className="col-12 mb-3">
						{!editarCampos && (
							<TextField
								disabled={true}
								label="Uso de CFDI:"
								variant='outlined'
								size="small"
								name='UseCfdi'
								autoComplete='off'
								value={Request.UseCfdi}
								onChange={handleInputChange}
								fullWidth
								inputProps={{ autoCapitalize: 'off' }}
							/>
						)}

						{editarCampos && (
							<FormControl variant="outlined" size="small" fullWidth={true}>
								<InputLabel htmlFor="usos-oulined">
									Uso de CFDI
								</InputLabel>
								<Select
									multiple
									labelWidth={80}
									value={usoCFDI}
									name="UseCfdi"
									onChange={handleChangeSelectMultiple}
									renderValue={renderSelectMultiple}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 48 * 4.5 + 8,
												width: 400,
											},
										}
									}
									}
									inputProps={{
										name: 'UseCfdi',
										id: "usos-oulined"
									}}>
									<MenuItem disabled value="">
										<em>Selecciona los que apliquen</em>
									</MenuItem>
									{
										cfdiUses.map((uses) => {
											return (
												<MenuItem key={uses.id} value={uses.key}>
													<Checkbox checked={usoCFDI.indexOf(uses.key) > -1}></Checkbox>
													<ListItemText primary={uses.key + ' - ' + uses.description}></ListItemText>
												</MenuItem>
											)
										})
									}
								</Select>
							</FormControl>
						)}
					</div>
					<div className="col-6 mb-3">
						<TextField
							select
							label="Régimen Receptor:"
							variant='outlined'
							size="small"
							value={Request.RegimenId}
							onChange={handleInputChange}
							name='RegimenId'
							autoComplete='off'
							fullWidth
							inputProps={{ autoCapitalize: 'off' }}
							SelectProps={{
								native: true,
							}}
						>
							{regimes.map((regimen) => (
								<option key={regimen.id} value={regimen.id}>
									{regimen.satCode} - {regimen.officialName}
								</option>
							))}
						</TextField>

					</div>
					<div className="col-6 mb-3">
						{(!editarCampos) &&
							<TextField
								label="Forma de pago:"
								variant='outlined'
								size="small"
								name='WayOfPayment'
								autoComplete='off'
								disabled={true}
								value={Request.WayOfPayment}
								onChange={handleInputChange}
								fullWidth
							></TextField>
						}
						{(editarCampos) &&
							<FormControl variant="outlined" size="small" fullWidth={true}>
								<InputLabel htmlFor="payments-oulined">
									Forma de Pago
								</InputLabel>
								<Select
									multiple
									labelWidth={80}
									value={payments}
									name="UseCfdi"
									onChange={handleChangeSelectPayments}
									renderValue={renderSelectPayments}
									MenuProps={{
										PaperProps: {
											style: {
												maxHeight: 48 * 4.5 + 8,
												width: 400,
											},
										}
									}
									}
									inputProps={{
										name: 'UseCfdi',
										id: "usos-oulined"
									}}>
									<MenuItem disabled value="">
										<em>Selecciona los que apliquen</em>
									</MenuItem>
									{
										wayOfPayments.map((pay) => {
											return (
												<MenuItem key={pay.id} value={pay.key}>
													<Checkbox checked={payments.indexOf(pay.key) > -1}></Checkbox>
													<ListItemText primary={pay.key + ' - ' + pay.description}></ListItemText>
												</MenuItem>
											)
										})
									}

								</Select>
							</FormControl>
						}
					</div>
					<div className="col-6 mb-3">
						{(!editarCampos) &&
							<TextField
								label="Método de pago:"
								variant='outlined'
								size="small"
								name='PaymentMethod'
								autoComplete='off'
								disabled={true}
								value={String(Request.PaymentMethod)}
								onChange={handleInputChange}
								fullWidth
								multiple
								inputProps={{ autoCapitalize: 'off' }}
							>
							</TextField>
						}

						{(editarCampos) &&
							<TextField
								select
								label="Método de pago:"
								variant='outlined'
								size="small"
								name='PaymentMethod'
								autoComplete='off'
								value={Request.PaymentMethod}
								onChange={handleInputChange}
								fullWidth
								multiple
								inputProps={{ autoCapitalize: 'off' }}
							>
								{
									metodosDePago.map((metodo) => {
										return (
											<option key={metodo.id} value={metodo.value}>
												{metodo.label}
											</option>
										)
									})
								}
							</TextField>
						}
					</div>
					<div className="col-6 mb-3">
						{(!editarCampos) &&
							<TextField
							label="Tipo de Gasto:"
							variant='outlined'
							size="small"
							name='ExpenseTypeId'
							autoComplete='off'
							value={Request.ExpenseTypeId}
							onChange={handleInputChange}
							fullWidth
							disabled={true}
							inputProps={{ autoCapitalize: 'off' }}
						/>
						}

						{(editarCampos) &&
							<TextField
								select
								label="Tipo de Gasto:"
								variant='outlined'
								size="small"
								name='ExpenseTypeId'
								autoComplete='off'
								value={Request.ExpenseTypeId}
								onChange={handleInputChange}
								fullWidth
								inputProps={{
									autoCapitalize: 'off',
									type: 'number'
								}}
								SelectProps={{
									native: true,
								}}
							>
								{
									expenseTypes.map((gasto) => {
										return (
											<option key={gasto.id} value={gasto.id}>
												{gasto.id} - {gasto.name}
											</option>
										)
									})
								}
							</TextField>
						}
					</div>
					<div className="col-6 mb-3">
						<TextField
							label="Límite:"
							variant='outlined'
							size="small"
							name='Limit'
							autoComplete='off'
							value={Request.Limit}
							onChange={handleInputChange}
							fullWidth
							inputProps={{
								autoCapitalize: 'off',
								type: 'number'
							}}
						/>
					</div>
					<div className="col-6 mb-3">
						<TextField
							label="Límite de la Forma de Pago:"
							variant='outlined'
							size="small"
							name='LimitPaymentForm'
							autoComplete='off'
							value={Request.LimitPaymentForm}
							onChange={handleInputChange}
							fullWidth
						/>
					</div>
					<div className="col-6 mb-3">
						<Checkbox
							name='Check'
							checked={Request.Check}
							value={Request.Check}
							onChange={hangleCheck}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
						<label>Check</label>
						<Checkbox
							name='isValidated'
							checked={Request.isValidated}
							value={Request.isValidated}
							onChange={hangleCheck}
							inputProps={{ 'aria-label': 'controlled' }}
						/>
						<label>Valido</label>
					</div>
				</div>
				<div className="row">
					<div className="col-12 d-flex justify-content-end align-items-end">
						<button className="btn btn-primary">
							Guardar cambios
						</button>
					</div>
				</div>
			</form>
		</div>
	);
};

export default EditClavProdServModal;
