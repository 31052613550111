import swal from "sweetalert";
import { fetchWithToken } from "../../helpers/fetch";
import { loadingModalMsn } from "../../helpers/UseSwal";
import { types } from "../../types/types";


export const startDownloadXMLSATRequest = (period) => {
  return async (dispatch) => {
    try {
      const resp = await fetchWithToken('api/DownloadXmlSAT/request', period, 'POST');
      const body = await resp.json();
      if (body.success) {
        dispatch(downloadXmlSATRequestStartLoaded(body.data));
      } else {
        // dispatch(downloadXmlSATRequestStartLoaded(body.data.requests));
      }
    } catch (err) {
      console.error(err);
    }
  }
}

const downloadXmlSATRequestStartLoaded = (requests) => ({
  type: types.downloadXMLRequestStartLoading,
  payload: requests
})

export const startDownloadXmlSATVerify = (requestId) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/verify/${requestId}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
    }
  }
}

export const startDownloadXMLDownloadRequest = (requestId) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/downloadRequest/${requestId}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
    }
  }
}

export const startDownloadXMLProccessDownload = (requestId) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/processDownload/${requestId}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
    }
  }
}

export const startDownloadXMLGetData = (requestId) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/getData/${requestId}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
    }
  }
}

export const startDownloadXMLProccessXMLDownloaded = (requestId) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/processXmlDownloaded/${requestId}`);
    const body = await resp.json();
    if (body.success) {
      //console.log(body)
    }
  }
}

export const getRequestByPeriod = (json) => {
  return async (dispatch) => {
    const resp = await fetchWithToken(`api/DownloadXmlSAT/request/getRequestByPeriod`, json, 'POST');
    const body = await resp.json();
    if (body.success) {
      dispatch(getRequestPeriod(body.data));
    }
  }
}

const getRequestPeriod = (json) => ({
  type: types.getRequestByPeriodStartLoading,
  payload: json
})

export const downloadSatStartLoading = (json, handleModal, msn, refreshComplementPayments) => {
  return async (dispatch) => {
    loadingModalMsn(true, msn);
    try {
      const resp = await fetchWithToken('api/DownloadXmlSAT/ByPasswd/DownloadXML', json, 'POST');
      const body = await resp.json();
      if (!body.success && body.data) {
        if (json.requestType === 1) {
          dispatch(getSesionSat(false));
        } else {
          dispatch(getSesionRetentionSat(false));
        }
        dispatch(getDownloadSatLoaded(body.data));
      }

      if (body.success && body.data) {
        handleModal();
        swal(body.data, "", "success").then(() => {
          window.location.reload();
        });
        if (json.requestType === 1) {
          dispatch(getSesionSat(true));
        } else {
          dispatch(getSesionRetentionSat(true));
        }
        refreshComplementPayments && refreshComplementPayments();
      }

      if (body.error === 'No se encontraron Xmls' || body.error === 'Ocurrio un error,Intenta más tarde por favor') {
        dispatch(getSesionSat(true));
        handleModal();
        swal(body.error, "", "warning");
      }

      loadingModalMsn(false);

      // Devuelve la respuesta para que pueda ser utilizada en el componente
      return body;
    } catch (error) {
      console.error("Error en la acción downloadSatStartLoading:", error);
      throw error;
    }
  };
};


const getDownloadSatLoaded = (json) => ({
  type: types.getDownloadSatStartLoading,
  payload: json
})


const getSesionSat = (flag) => ({
  type: types.getSessionSat,
  payload: flag
})

export const sesionSat = (handleModal = null) => {
  return async (dispatch) => {
    handleModal();
    dispatch(getSesionSat(true))

  }
}
export const sesionRetentionSat = (handleModal = null) => {
  return async (dispatch) => {
    handleModal();
    dispatch(getSesionRetentionSat(true))

  }
}

const getSesionRetentionSat = (flag) => ({
  type: types.getSessionRetentionSat,
  payload: flag
})



