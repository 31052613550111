import { fetchWithToken } from "../../helpers/fetch";
import { loadingModal } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const mailboxStartLoading = () => {
    return async(dispatch) => {
      loadingModal(true);
      const resp = await fetchWithToken(`api/Mailbox/Get`);
      const body = await resp.json();    
      if (body.success){
        dispatch(loadedmailbox(body.data))
      }
      loadingModal(false);
    }
  }
  
  const loadedmailbox= (mails) => ({
    type: types.mailboxStartLoading,
    payload: mails
  })


  export const mailboxUpdate = (id) => {
    return async(dispatch) => {
      loadingModal(true);
      const resp = await fetchWithToken(`api/Mailbox/Read`, id, 'POST');
      const body = await resp.json();    
      if (body.success){
        dispatch(mailboxStartUpdate(id))
      }
      loadingModal(false);
    }
  }
  
  const mailboxStartUpdate= (mail) => ({
    type: types.mailboxStartUpdate,
    payload: mail
  })