import React, { useEffect } from 'react'
import { TextField } from '@material-ui/core'
import { useDispatch } from 'react-redux';
import { crmRenovationsStartLoading } from '../../../../actions/crm/renovations';
import { obtainMonths, obtainYears } from '../../../../helpers/declarationPeriod';
import { useForm } from '../../../../hooks/useForm'
import { CrmAccountingRenovationsIndexScreen } from './accountingRenovations/CrmAccountingRenovationsIndexScreen';
import { CrmInvocingRenovationsIndexScreen } from './invoicingRenovations/CrmInvocingRenovationsIndexScreen';

export const CrmRenovationsIndexScreen = () => {

  const { formValues, setFormValues, handleInputChange } = useForm({
    dataDownload: [],
    monthsOb: obtainMonths(),
    monthSelected: '',
    years: obtainYears().reverse(),
    yearselected: '',
  });

  const dispatch = useDispatch();

  const { monthSelected, monthsOb, years, yearselected } = formValues;

  useEffect(() => {
    if (monthSelected !== '' && yearselected !== '') {
      dispatch(crmRenovationsStartLoading({
        month: monthSelected,
        year: yearselected
      }))
    }
  }, [dispatch, monthSelected, yearselected]);

  useEffect(() => {
    const date = new Date();
    setFormValues(prevState => {
      return {
        ...prevState,
        monthSelected: date.getMonth() + 1,
        yearselected: date.getFullYear()
      }
    })
  }, [setFormValues])

  return (
    <div>
      <div className="container">
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div className="card-header">
            <h5>Renovaciones</h5>
          </div>
        </div>

        <div className="col-md-12 mt-2">
          <div className="row">
            <div className="col-md-2">
              <TextField select variant="outlined" label="Mes" size="small"
                fullWidth={true} name="monthSelected"
                value={monthSelected} onChange={handleInputChange}
                SelectProps={{ native: true, }}>
                {
                  monthsOb.map((month, index) => {
                    return (
                      <option key={index} value={month.month + 1}>
                        { month.label}
                      </option>
                    )
                  })
                }
              </TextField>
            </div>

            <div className="col-md-2">
              <TextField select variant="outlined" label="Año" size="small"
                fullWidth={true} name="yearselected"
                value={yearselected} onChange={handleInputChange}
                SelectProps={{ native: true, }}>
                {
                  years.map((yearItem, index) => {
                    return (
                      <option key={index} value={yearItem}>
                        { yearItem}
                      </option>
                    )
                  })
                }
              </TextField>
            </div>
          </div>
        </div>

        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <nav className="mt-4">
            <div className="nav nav-tabs" id="nav-tab" role="tablist">
              <a className="nav-item nav-link active" id="nav-accounting-tab" data-toggle="tab" href="#nav-accounting" role="tab" aria-controls="nav-accounting" aria-selected="true">
                Contabilidad
                </a>
              <a className="nav-item nav-link" id="nav-facturacion-tab" data-toggle="tab" href="#nav-facturacion" role="tab" aria-controls="nav-facturacion" aria-selected="true">
                Facturación
              </a>
            </div>
          </nav>

          <div className="tab-content" id="nav-tabContent">
            <div className="tab-pane fade show active" id="nav-accounting" role="tabpanel" aria-labelledby="nav-accounting-tab">
              <CrmAccountingRenovationsIndexScreen />
            </div>
            <div className="tab-pane fade" id="nav-facturacion" role="tabpanel" aria-labelledby="nav-facturacion-tab">
              <CrmInvocingRenovationsIndexScreen />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
