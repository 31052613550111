import swal from "sweetalert";
import { fetchWithApiKey, fetchWithApiKeyBilling, fetchWithToken } from "../../helpers/fetch";
import { loadingModalMsn } from "../../helpers/UseSwal";
import { types } from "../../types/types";

export const startRegisterBill = ( bill, token ) => {
  loadingModalMsn(true,"Registrando Factura")
  return async(dispatch) => {
    const resp = await fetchWithApiKeyBilling('api/Invoice', bill, 'POST', token);
    const body = await resp.json();
    if (body.success){
        swal('Exito', 'Se ha registrado exitosamente', 'success');
        //history.push(route);
        dispatch( registerActiveBill( [body.data] ) )
    }else{
        console.log('BODY-FUERA',body)
        swal('Error', 'Intente de nuevo, por favor', 'error');
      }
      loadingModalMsn(false)
    }
  }

  const registerActiveBill  = ( data ) => ({
    type: types.registerBill,
    payload: data
  })
