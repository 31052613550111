import React, { useEffect } from 'react'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux';
import { mailboxStartLoading, mailboxUpdate } from '../../actions/mailbox/mailbox';


export const ModuleMailboxScreen = () => {

  const dispatch = useDispatch();

  const { mailboxGet } = useSelector(state => state.mail);

  useEffect(() => {
    dispatch(mailboxStartLoading());
  }, [dispatch]);

  useEffect(() => {

    renderNotification()
  }, [mailboxGet]);

  const handleCellClick = (e) => {
    e.preventDefault();
    dispatch(mailboxUpdate({
        id: e.target.textContent
        }   
    ))
  }

  const renderNotification =() =>{
      return(
        <div style={{width: "500px", height: "300px"}}>
        <h4 className="text-center">Mensajes sin leer</h4>
        <hr />
        <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12 mt-2">
                  <div className="card">
                      <div className="card-body">
                          <TableContainer>
                              <Table>
                                  <TableHead>
                                      <TableRow>
                                          <TableCell aling="center"></TableCell>
                                          <TableCell aling="center">Contenido</TableCell>
                                      </TableRow>
                                  </TableHead>
                                  <TableBody>
                                      {
                                      mailboxGet.length === 0 ?
                                      (<TableRow>
                                          <TableCell colSpan="10">
                                          <div className="alert alert-info text-center" role="alert">
                                              No hay mensajes
                                          </div>
                                          </TableCell>
                                      </TableRow>)
                                      :
                                      (
                                          mailboxGet.map((mail, index) => {
                                          return(
                                              <TableRow key={index}>
                                              <TableCell onClick={handleCellClick}> 
                                                  <i className="fa fa-check-circle-o" key={mail.id}>   
                                                      {mail.id}
                                                  </i>
                                              </TableCell>
                                              <TableCell>{mail.content} 
                                              <a href={mail.urlReference}> Ir </a></TableCell>
                                              </TableRow>
                                          )
                                          })
                                      )
                                      }
                                  </TableBody>
                              </Table>
                          </TableContainer>
                      </div>
                  </div>
              </div>
      </div>
      )
  }
  
  return (renderNotification())

}

